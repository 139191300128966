.TableList {
	&-heading {
		.Heading-title {
			text-align: center;
			margin-top: 0;
		}
	}

	&-item {
		@include at(md) {
			display: flex;
			padding-bottom: 42px;
		}

		@include at(lg) {
			padding-bottom: 40px;
		}

		@include at(sl) {
			padding-bottom: 50px;
		}

		&-description {
			@include at(md) {
				margin-left: auto;
				padding-top: 0;
				text-align: right;
			}

			&-cta {
				@include at(md) {
					text-align: right;
				}
			}
		}
	}
}