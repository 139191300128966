///
/// Simple card.
///
@mixin card() {
    $b: &;

    background-color: $white;
    box-shadow: $med-shadow;
    margin-bottom: $v-space;
    z-index: 1;

    &__container {
        display: grid;
        grid-gap: 1rem;
        grid-template: "icon" "heading" "content" 1fr "actions" / auto;
        height: 100%;
    }

    &__icon,
    &__heading,
    &__content,
    &__actions {
        justify-self: center; // For IE11 (instead of justify-items)
        padding-left: $h-space;
        padding-right: $h-space;
        position: relative;
    }

    &__tag {
        align-self: start;
        color: $meta-text;
        font-size: .875rem;
        font-weight: bold;
        grid-area: icon;
        justify-self: start;
        padding: $thin-padding;
        text-transform: uppercase;

        &.-overlay {
            color: $black;
        }
    }

    &__icon {
        grid-area: icon;
        padding-top: $v-space;
        text-align: center; // For IE11
    }

    &__heading {
        grid-area: heading;
    }

    &__content {
        grid-area: content;
    }


    &__background {
        background: $gray-100 center/cover;
        grid-column: 1;
        grid-row: icon / heading;
        height: 100%;
        justify-self: stretch;
    }

    &__actions {
        grid-area: actions;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        justify-self: stretch;
        margin-top: auto;
        padding-bottom: $v-space;

        &.-attached {
            justify-content: stretch;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;

            .card__action {
                flex-grow: 1;
            }
        }
    }
    &__titles {
        padding: 0.5rem 1.5rem;
    }
    &__title{
        display: flex;
        justify-content: space-between;
    }

    &__action {
        flex-basis: max(9rem, 50%);
    }

    &.-clickable {
        cursor: pointer;
    }

    &.-wide {
        #{$b}__container {
            grid-gap: 1rem 0;
            grid-template: "background heading" "background content" 1fr "background actions" / 1fr 1fr;
        }

        #{$b}__background {
            grid-area: background;
        }

        #{$b}__backgroundImg {
            height: 100%;
            object-fit: cover;
        }

        #{$b}__heading {
            padding-top: $v-space;
        }
    }
}

.card {
    @include card;
}


@mixin offers__cards__card() {
    $b: &;
    .card__link{
        position: relative;
    }
    .card__link:hover,
        .card__link:focus{
            .card__background::after {
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.9) 100%);
                backdrop-filter: blur(13px);
            }
        }
    .card__background {
        padding-top: 125%;
    }
    .card__background:after {
        background: linear-gradient(180deg, transparent 0, #000);
        bottom: 0;
        content: "";
        display: block;
        height: 70%;
        position: absolute;
        width: 100%;
        z-index: 1;
        transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
    }
    .card__overlay {
        bottom: 30px;
        display: flex;
        flex-direction: column;
        left: 30px;
        margin-right: 30px;
        overflow-wrap: anywhere;
        pointer-events: none;
        position: absolute;
        z-index: 2;
        color: #fff;
        .card__heading {
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0.25em;
            line-height: 1.3333333333em;
            text-transform: uppercase;
            padding: 0;
            margin: 0;
        }
        .card__actions {
            padding: 0;
            font-size: 0.9375rem;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.0333333333em;
            line-height: 1.6666666667em;
            display: block;
        }
    }
}

.offers__cards__card {
    @include offers__cards__card;
}

@mixin rooms_cards__card() {

    .card__heading {
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.3333333333em;
        text-transform: uppercase;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }
    .card__actions {
        padding: 0;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.0333333333em;
        line-height: 1.6666666667em;
        display: block;
        margin: 0 1.5rem;
        border-top: 1px solid rgb(238, 238, 238);
        .card__action {
            flex-grow: 1;
            display: block;
            padding: 1.5rem 0;
            margin-bottom: 0.5rem;
            font-weight: 500;
            text-decoration: underline;
            text-align: center;
            color: #000;
            opacity: 1;
            transition: opacity .3s ease-out;
        }
        .card__action:hover{
            opacity: 0.7;
        }
    }
}


.rooms_cards__card {
    @include rooms_cards__card;
}