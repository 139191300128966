@use '../utils' as *;

/*----------------------------------------*/
/*  03. Banner CSS START
/*----------------------------------------*/
.banner{
    position: relative;
	z-index: 1;
    &__slider{
        position: relative;
    }
    &__border{
        position: absolute;
       height: 100%;
       width: 2px;
       background: var(--tp-common-white);
       opacity: .30;
       left: 24.3%;
    }
    &__border-2{
        position: absolute;
       height: 100%;
       width: 2px;
       background: var(--tp-common-white);
       opacity: .30;
       left: 44.4%;
       top: 0;
    }
    &__border-3{
        position: absolute;
       height: 100%;
       width: 2px;
       background: var(--tp-common-white);
       opacity: .30;
       left: 64.4%;
       top: 0;
    }
    &__area{
		&-3{
			background-image: -moz-linear-gradient( 145deg, rgb(255,235,235) 0%, rgb(240,240,240) 100%);
			background-image: -webkit-linear-gradient( 145deg, rgb(255,235,235) 0%, rgb(240,240,240) 100%);
			background-image: -ms-linear-gradient( 145deg, rgb(255,235,235) 0%, rgb(240,240,240) 100%);
			position: relative;
			z-index: 1;
		}
	}
	&__content{
		&-meta{
			font-size: 14px;
			background: var(--tp-common-white);
			padding: 5px 10px;
			border-radius: 6px;
			display: inline-block;
		}
		&-title{
			font-size: 60px;
            @media #{$lg} {
                font-size: 50px;
            }
            @media #{$sm,$xs} {
                font-size: 40px;
            }
		}
	}
	&__item{
		&-3{
			padding-top: 190px;
            @media #{$lg} {
                padding-top: 145px;
            }
            @media #{$md} {
                padding-top: 100px;
            }
            @media #{$sm,$xs} {
                padding-top: 80px;
            }
		}
	}
	&__button{
		display: flex;
		align-items: center;
        @media #{$xs} {
            display: block;
        }
	}
	&__cinfo{
		margin-left: 30px;
        @media #{$xs} {
            margin-left: 0;
           margin-top: 20px;
        }
		& a{
			display: inline-block;
			font-size: 16px;
			font-weight: var(--tp-fw-medium);
			color: var(--tp-heading-primary);
			& span{
				display: block;
				font-size: 14px;
				font-weight: var(--tp-regular);
				color: var(--tp-text-body);
			}
		}
	}
	&__d-image{
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
        @media #{$md,$sm,$xs} {
            display: none;
        }
		&::before{
			position: absolute;
			content: '';
			background: var(--tp-theme-1);
			width: 100%;
			height: 100%;
			top: 0;
		}
	}
	&__shape{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		z-index: -1;
	}
	&__shape-2{
		position: absolute;
		top: 0px;
		left: 7%;
		z-index: -1;
        @media #{$md,$sm} {
            left: -40%;
        }
        @media #{$xs} {
            display: none;
        }
	}
    &__height-3{
        min-height: 970px;
        @media #{$lg} {
            min-height: 875px;
        }
    }
    &__image{
        @media #{$md,$sm,$xs} {
            margin-top: 50px;
        }
    }
}
//sd banner 
.banner-image{
    position: relative;
}
.sd-banner-area{
    position: relative;
}
.sd-banner__item{
    @include background();
}
.sd-content{
    background: var(--tp-theme-1);
    padding: 45px 50px;
    position: absolute;
    bottom: 0;
    width: 100.5%;
    z-index: 2;
    left: -2px;
    @media #{$lg} {
        bottom: 50px;
        width: 90%;
        z-index: 2;
        left: 0;
        right: 0;
        margin: auto;
    }
    @media #{$md} {
        bottom: 40px;
        width: 90%;
        z-index: 2;
        left: 0;
        right: 0;
        margin: auto;
    }
    @media #{$sm} {
        bottom: 30px;
        width: 90%;
        z-index: 2;
        left: 0;
        right: 0;
        margin: auto;
    }
    @media #{$sm,$xs} {
        bottom: 20px;
        width: 90%;
        z-index: 2;
        left: 0;
        right: 0;
        margin: auto;
    }

    & .sd-meta{
        font-size: 16px;
        line-height: 1.2;
        color: var(--tp-common-white);
    }
    & h5{ 
        font-size: 36px;
        line-height: 1.3;
        color: var(--tp-common-white);
    }
    & .project-info{
        margin-top: 100px;
        & i{
            font-size: 15px;
            color: var(--tp-heading-primary);
            height: 50px;
            width: 50px;
            line-height: 50px;
            background: var(--tp-common-white);
            border-radius: 6px;
            text-align: center;
            font-weight: var(--tp-fw-medium);
            margin-right: 20px;
            transition: .3s;
            &:hover{
                background: var(--tp-heading-primary);
                color: var(--tp-common-white);
            }
        }
        & span{
            font-size: 16px;
            color: var(--tp-common-white);
            font-weight: var(--tp-fw-sbold);
        }
    }
    & .sd-bg-icon{
        position: absolute;
        bottom: 10%;
        left: 35%;
        z-index: -1;
        & i{
            font-size: 230px;
            color: #e4e4e421;
        }
        @media #{$xs} {
            left: 4%;
        }
    }
}
.banner-slide-height {
	height: 800px;
	position: relative;
    @media #{$lg} {
        height: 700px;
    }
    @media #{$md} {
        height: 600px;
    }
    @media #{$sm} {
        height: 600px;
    }
    @media #{$xs} {
        height: 550px;
    }
}
.slide-border {
	border-right: 2px solid var(--tp-common-white);
	opacity: 0.3;
	height: 100%;
}

.main-search-input-wrap {
	max-width: 800px;
    position: relative;	
}
.main-search-input-wrap.msiw-center {
	margin: 0 auto;
}
.main-search-input {
    background: #fff;
    padding: 0 120px 0 0;
    border-radius: 6px;
	margin-top: 30px;
}
.main-search-input-wrap.msiw-column .main-search-input {
	padding: 25px 20px 10px;
	margin-top: 10px;
}
.msiw-column_title {
	text-align: left;
	margin-bottom: 15px;
	font-weight: 600;
	font-size: 13px;
	color: #fff;
}
.shadow_msiw .main-search-input {
	box-shadow: 0px 0px 0px 6px rgba(255,255,255,0.2);
}
.main-search-input-item {
    float: left;
    width: 33.3%;
    box-sizing: border-box;
    border-left: 1px solid #eee;
    height: 60px;
    position: relative;
}
.main-search-input-wrap.msiw-column .main-search-input-item  {
	width: 100%;
	border:none;
	height: auto;
	float: left;
	margin-bottom: 10px;
}
.main-search-input .main-search-input-item:first-child , .main-search-input-wrap.msiw-column .main-search-input-item:first-child {
	border:none;
}
.main-search-input-item input {
    float: left;
    border: none;
    width: 100%;
    height: 60px;
    padding-left: 20px;
	font-weight: 500;
}
.main-search-input-item input::-webkit-input-placeholder {
	color: #7d93b2;
	font-weight: 400;
	font-size: 12px;
	position: relative;
}
.main-search-input-item input:first-child {
    border-radius: 6px 0 0 6px;
}
.main-search-input-wrap.msiw-column .main-search-input-item  input  {
	border-radius: 4px;
	height: 50px;
	background: #F5F7FB;
	border: 1px solid #e5e7f2;
}
.main-search-button {
    position: absolute;
    right: 0px;
    height: 60px;
    width: 120px;
    color: #fff;
    top: 0;
    border: none;
    border-radius:0 6px 6px 0;
    cursor: pointer;
    background: var(--tp-theme-1);
}
.main-search-input-wrap.msiw-column  .main-search-button {
	top: auto;
	width: 100%;
	bottom: 10px;
	height: 45px;
	position: relative;
	border-radius: 4px;
	margin-top:15px;
}
.main-search-button i {
	margin-left:6px; 
	font-size: 11px;
}
.main-search-input-item .nice-select {
    background: #fff;
    height: 60px;
    line-height: 50px;
    border: none;
}
.main-search-input-wrap.msiw-column .main-search-input-item .nice-select {
	background: #F5F7FB;
	border: 1px solid #e5e7f2;
    height: 50px;
    line-height: 50px;
}
.main-search-input-item .nice-select:focus-visible{
    outline: none;
}
.main-search-input-item .nice-select .list {
	margin-top: 0;
}