
///
///

@mixin cards($gutter: $gutter) {
    @include at(md) {
        display: flex;
        flex-wrap: wrap;
        &__card {
            @include wrap(1, 2, $gutter);
        }
    }
    @include at(lg) {

        &__card {
            @include wrap(1, 3, $gutter);
        }
    }
}

.cards {
    @include cards;
}

@mixin offers__cards($gutter: $gutter) {
    @include at(md) {
        display: flex;
        flex-wrap: wrap;
        &__card {
            @include wrap(1, 3, $gutter);
        }
    }
    @include at(lg) {

        &__card {
            @include wrap(1, 4, $gutter);
        }
    }
}

.offers__cards {
    @include offers__cards;
}

@mixin rooms_cards($gutter: $gutter) {
    @include at(md) {
        display: flex;
        flex-wrap: wrap;
        &__card {
            @include wrap(1, 1, $gutter);
        }
    }
    @include at(lg) {

        &__card {
            @include wrap(1, 2, $gutter);
        }
    }
}

.rooms_cards {
    @include rooms_cards;
}

