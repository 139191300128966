@mixin team {
    $b: &;

    &__members {
        align-items: center;
        display: flex;
        flex-direction: column;

        @include at(lg) {
            flex-flow: row wrap;
            justify-content: flex-start;
        }
    }

    &__member {
        @extend .avatar, .-horizontal;

        @include at(lg) {
            @include wrap(1, 3, $gutter);
            flex-direction: column;
            height: auto;
            text-align: center;
        }
        @include at(xl) {
            @include wrap(1, 4, $gutter);
        }
    }
}

.team {
    @include team;
}
