.siteFooter {
    background-color: $black;
    color: $white;
    padding: 6rem 0 1rem;

    a {
        color: $white;
    }

    &__container {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;

        > * {
            flex: 1;
        }
    }

    &__links {
        flex: 2;
        .linkGroup__link{
            color: $gray-200;
        }
    }

    &__brand {
        align-items: center;
        color: $primary;
        display: flex;
        margin-right: 2rem;
        padding: $thin-v-space 0;
    }

    &__logo {
        margin-right: .5rem;
    }

    &__socialLinks {
        display: flex;
        justify-content: flex-end;
    }

    &__socialLink {
        padding: 1rem 0;

        & + & {
            margin-left: .5rem;
        }
    }

    &__utility {
        color: $gray-300;
        display: flex;
        font-size: .875rem;
        justify-content: space-between;
        padding-top: 2rem;
        position: relative;
        margin-top: 2rem;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 40px;
            width: calc(100% - 80px);;
            height: 1px;
            background-color: $gray-600;
        }
        a {
            color: $gray-300;
        }
    }
}
