
@use '../utils' as *;

/*----------------------------------------*/
/*  00. Footer CSS START
/*----------------------------------------*/

.fl-wrap {
    float: left;
    width: 100%;
    position: relative;
}
.pwh_bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 40px;
    background-size: cover;
    background-attachment: scroll;
    background-position: left;
    background-repeat: repeat;
    background-origin: content-box;
    background: url(../../media/city-bg.png);
}
.subscribe-wrap:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 50%;
    background: var(--tp-grey-8);
    z-index: 1;
}
.subscribe-container {
    padding: 90px 60px;
    border-radius: 10px;
    z-index: 2;
    overflow: hidden;
    background: var(--tp-theme-1);
    .pwh_bg {
        bottom: -100px;
        top: -150px;
        left: -10%;
        right: 50%;
        opacity: 0.2;
    }
}
.mrb_dec {
    position: absolute;
    width: 250px;
    height: 250px;
    border-radius: 100%;
    background: rgba(255,255,255,0.1);
    top: -60px;
    left: -130px;
}
.mrb_dec.mrb_dec3 {
    left: auto;
    top: auto;
    right: -50px;
    width: 250px;
    height: 250px;
    bottom: -120px;
}
.subscribe-header {
    text-align: left;
    position: relative;
    top: -10px;
    h4 {
        color: #fff;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 600;
    }
    h3 {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
    }
}
#subscribe .enteremail {
    background: #fff;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    padding: 0 120px 0 15px;
    box-sizing: border-box;
    z-index: 1;
    border: none;
}
#subscribe-button {
    position: absolute;
    right: 5px;
    width: 120px;
    height: 40px;
    line-height: 40px;
    font-weight: 500;
    top: 13px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    z-index: 2;
    color: #fff;
    background: var(--tp-theme-1);
}
.main-footer {
    padding: 90px 0 0;
    background: #F5F7FB;
}
.footer-widget-logo img {
    width: 250px;
    height: auto;
    float: left;
    position: relative;
    top: -20px;
    @media #{$md,$sm,$xs} {
        width: 100%;
    }
}
.footer-widget-logo {
    margin-bottom: 10px;
}
.fw_hours span {
    display:block;
    margin-top: 10px;
    text-align: left;
    font-weight: 600;
    font-size: 30px;
    color: #144273;
    @media #{$md} {
        font-size: 18px;
    }
}
.footer-widget-title {
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    color: #566985;
    margin-bottom: 20px;
}
.footer-list li{
    display: block;
    text-align: left;
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    list-style: none;
}
.contats-list li span, .footer-contacts li span {
    float: left;
    color: #7d93b2;
    position: relative;
}
.contats-list li span i, .footer-contacts li span i {
    margin-right: 6px;
    color: var(--tp-theme-1);
}
.footer-list li a {
    color: #144273;
}
.footer-list li:before {
	font-family: "Font Awesome 6 Pro";
	content: "\f0da";
    position: absolute;
    left:0;
    top: 10px;
    font-size: 11px;
    transition: all 200ms linear;
}
.footer-list li:hover:before{
    left:4px;
}
.contats-list li, .footer-contacts li {
    float: left;
    width: 100%;
    margin-bottom: 15px;
    text-align: left;
    font-weight: 600;
    font-size: 13px;
    list-style: none;
}
.footer-contacts li a {
	color: #144273;
}
.footer-contacts li span {
    min-width: 65px;
}
.api-btn {
    display:block;
    padding: 15px 0;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
    background: var(--tp-theme-1);
}
.api-btn i {
    margin-right: 10px;
}
.footer-social {
    margin:20px 0 0 0; 
}
.footer-social li{
    float:left;
    list-style: none;
}
.footer-social li {
    margin-right: 6px;
}
.footer-social li a {
    float:left;
    width: 36px;
    height: 36px;
    line-height: 36px;
    color: #fff;
    border-radius: 2px;
    font-size: 16px;
    text-align: center;
    background: var(--tp-theme-1);
}
.footer-social li a:hover {
	color: #fff
}
.footer-inner {
    padding-bottom: 60px;
}
.sub-footer {
    padding: 25px 0;
}
.copyright , .subfooter-nav li {
    float:left;
}
.copyright {
    font-weight: 500;
    font-size: 14px;
    color: #144273;
}
.subfooter-nav {
    float:right;
    
}
.subfooter-nav li {
    margin-right: 10px;
    font-weight: 500;
    list-style: none;
}
.subfooter-nav li a:hover {
    color:#000;
}
.gray-bg {
    background: #F5F7FB;
}