@use '../utils' as *;

/*----------------------------------------*/
/*  02. HEADER CSS START
/*----------------------------------------*/

.header__area{
    position: relative;
    z-index: 222;
    width: 100%;
}
//sticky
.header__sticky {
	position: fixed !important;
	left: 0;
	margin: auto;
	top: 0;
	width: 100%;
	box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
	z-index: 99;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
    -webkit-animation: sticky 1s;
    animation: sticky 1s;
	background: var(--tp-common-white);
}
.header__sticky.header__bottom-2 {
    padding: 0px 0;
    @media #{$md,$sm,$xs} {
        padding: 10px 0;
    }
}
.header__sticky.header__area-3{
    background: #ffebeb;
    padding: 25px 0;
}

//logo
.logo{
    width: 180px;
    height: 100px;
    background-color: var(--tp-theme-1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.header-search-button {
    margin-left: 20px;
    top: 0px;
    width: 200px;
    height: 40px;
    border-radius: 40px;
    line-height: 40px;
    position: relative;
    background: #F5F7FB;
    cursor: pointer;
    color: #144273;
    font-weight: 500;
    transition: all 200ms linear;
    transition-delay: 0.1s;
    overflow: hidden;
    @media #{$lg,$md,$sm,$xs} {
        width:40px;
        margin-left: 0;
    }
    i {
        margin-right: 20px;
        font-weight: 800;
        position: relative;
        float: left;
        height: 40px;
        width: 40px;
        line-height: 40px;
        margin-left: 10px;
        transition: all 200ms linear;
        transition-delay: 0.1s;
    }
    span {
        position: relative;
        right: 30px;
    }
    &:hover {
        box-shadow: 0 2px 16px rgba(58, 87, 135, 0.1);
    }
}
.hsbclose {
	width:40px;
	text-align:center;
    & i {
        margin:0;
    }
    & span {
        top:-50px;
        visibility:hidden;
    }
}
.hsbclose i:before, .show-header-modal.scwllink i:before {
    content: "\f00d";
}

.header-opt_btn {
    position: relative;
    float: left;
    cursor: pointer;
    top: 0;
    font-size: 16px;
    height: 100px;
    line-height: 100px;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    width: 70px;
    color: #878C9F;
    transition: all 200ms linear;
    text-align: center;
    span {
        position: relative;
        display: block;
        padding-right: 10px;
    }
    span:after {
        font-family: "Font Awesome 6 Pro";
        content: "\f0d7";
        position: absolute;
        right: 12px;
        top: 0;
        font-weight: 600;
        font-size: 12px;
        transition: all 200ms linear;
    }
}
.tolt:before, .tolt:after {
    transform: translate3d(0,0,0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform;
    opacity: 0;
    pointer-events: none;
    transition: all .2s ease-in-out;
    position: absolute;
    box-sizing: border-box;
    z-index: 10;
    transform-origin: top;
}
.tolt:before {
    background-size: 100% auto!important;
    content: "";
}
.tolt::after {
    border-radius: 2px;
    background: #3270FC;
    color: #fff;
    content: attr(data-tooltip);
    font-size: 11px;
    font-weight: 400;
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
    white-space: nowrap;
    box-sizing: content-box;
}
.tolt[data-microtip-position|="bottom"]::after {
    margin-top: 11px;
}
.tolt[data-microtip-position|="bottom"]::after {
    transform: translate3d(-50%,-10px,0);
    top: 100%;
    left: 50%;
}
.header-opt_btn.scwheader-opt-btn span:after {
    transform: rotateX(180deg);
}
.header-opt-modal {
    position: absolute;
    right: 146px;
    top: 100%;
    margin-top: 1px;
    background: #fff;
    padding: 15px 0;
    width: 150px;
    border: 1px solid #eee;
    border-top: none;
    overflow: hidden;
    border-radius: 0 0 6px 6px;
    z-index: 1;
    box-shadow: 0 15px 5px rgb(0 0 0 / 3%);
    opacity: 0;
    visibility: hidden;
    transition: all 300ms linear;
    margin-right: -20px;
}
.vis-head-mod {
    opacity: 1;
    margin-right: 0;
    visibility: visible;
}
.fl-wrap {
    float: left;
    width: 100%;
    position: relative;
}
.header-opt-modal-item {
    padding: 6px 0;
    h4 {
        display: block;
        cursor: pointer;
        text-align: left;
        padding: 0 20px 6px;
        color: #144273;
        font-weight: 600;
        font-size: 13px;
        position: relative;
    }
}
.header-opt-modal-list {
    display: none;
    li {
        text-align: left;
        padding: 0 20px;
        font-weight: 500;
        margin-top: 4px;
        a {
            color: #7d93b2;
            display: block;
            padding: 4px 6px;
            position: relative;
        }
    }
}
.header-search-wrapper {
    position: absolute;
    top: 100%;
    right: 0px;
    width: 300px;
    display: none;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s linear;
    background: #F5F7FB;
    border: 1px solid #e5e7f2;
    padding: 25px 20px;
    border-radius: 0 0 6px 6px;
    margin-left: -20px;
    transition-delay: 0.1s;
    box-shadow: 0 15px 5px rgb(0 0 0 / 3%);
    &.vis-search {
        opacity:1;
        visibility: visible;
        margin-left:0;
    } 
}
.custom-form label {
	float:left;
	position:relative;
	width:100%;
	text-align:left;
	font-weight:500;
    color: #878C9F;
    font-size: 12px;
	z-index:10;
	margin-bottom: 10px;
}
.header-search-wrapper .custom-form label {
    color: #144273;
}
.custom-form input {
    margin-bottom: 20px;
}
.header-search-wrapper .custom-form input {
    padding-left: 20px !important;
}
.custom-form textarea, .custom-form input[type="text"], .custom-form input[type=email], .custom-form input[type=password], .custom-form input[type=button], .listsearch-input-item input[type="text"] {
    float: left;
    border: 1px solid #e5e7f2;
    background: #F5F7FB;
    width: 100%;
    padding: 15px 20px 15px 70px;
    border-radius: 4px;
    color: #7d93b2;
    font-size: 12px;
    -webkit-appearance: none;
    outline: none;
    overflow: hidden;
    z-index: 1;
    font-weight: 400;
}
.header-search-wrapper .nice-select, .header-search-wrapper .custom-form input[type="text"] {
    background: #fff;
}
.header-search-wrapper .nice-select{
    line-height: 40px;
}
.custom-form button {
    outline: none;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: 20px;
    background: var(--tp-theme-1);;
}
.header-search-wrapper .custom-form .btn {
    color: #fff;
    font-weight: 500;
    width: 100%;
    padding: 12px 0;
    border: none;
    border-radius: 4px;
}


//main-menu
.main-menu{
    & ul{
        & li{
            display: inline-block;
            margin-right: 40px;
            position: relative;
            margin-bottom: 0;
            @media #{$lg} {
                margin-right: 22px;
            }
            &.has-dropdown{
                & > a{
                    padding-right: 14px;
                    &::after{
                        position: absolute;
                        content: '\f107';
                        right: -4px;
                        top: 42px;
                        font-size: 14px;
                        font-size: 14px;
                        color: var(--tp-grey-2);
                        font-family: "Font Awesome 6 Pro";
                    }
                }
            }
            & a{
                color: var(--tp-grey-2);
                font-size: 18px;
                line-height: 20px;
                padding: 40px 0;
                display: inline-block;
                transition: .3s;
            }
            & .active{
                color: var(--tp-common-white);
            }
            &:hover > a{
                color: var(--tp-theme-1);
                &::after{
                    color: var(--tp-theme-1);
                }
            }
            &:hover .sub-menu {
                visibility: visible;
                opacity: 1;
                top: 100%;
            }
            & .sub-menu{
                position: absolute;
                left: 0;
                top: 110%;
                min-width: 220px;
                background-color: var(--tp-common-white);
                opacity: 0;
                visibility: hidden;
                z-index: 99;
                -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
                -moz-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
                box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
                padding: 20px 0px 15px;
                transition: 0.3s;
                border-top: 4px solid var(--tp-theme-1);
                & li{
                    margin: 0;
                    padding: 0;
                    display: inline;
                    position: relative;
                    width: 100%;
                    display: block;
                    & a{
                        font-size: 16px;
                        font-weight: 400;
                        display: block;
                        padding: 5px 25px;
                        transition: 0.3s;
                        color: var(--tp-heading-primary);
                        text-transform: capitalize;
                    }
                    & .active {
                        color: var(--tp-theme-1);
                    }
                    &:hover > a{
                        color: var(--tp-theme-1);
                    }
                    & > .sub-menu{
                        left: 120%;
                        top: 0;
                        visibility: hidden;
                        opacity: 0;
                    }
                    &:hover > .sub-menu{
                        left: 100%;
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }
    &-2{
        & ul{
            & li{
                & a{
                    color: var(--tp-heading-primary);
                    padding: 40px 0;
                }
                &:hover > a{
                    color: var(--tp-theme-1);
                    &::after{
                        color: var(--tp-theme-1);
                    }
                }
                &.has-dropdown{
                    & > a{
                        padding-right: 14px;
                        &::after{
                            position: absolute;
                            content: '\f107';
                            right: -4px;
                            top: 22px;
                            font-size: 14px;
                            font-size: 14px;
                            color: var(--tp-grey-2);
                            font-family: "Font Awesome 6 Pro";
                        }
                    }
                }
                & .has-dropdown:hover > a::after{
                    color: var(--tp-theme-1);
                }
            }
        }
        & ul li.has-dropdown:hover > a::after {
            color: var(--tp-theme-1);
        }
    }
    &-3{
        & ul{
            & li{
                display: inline-block;
                margin-right: 40px;
                position: relative;
                @media #{$xl} {
                    margin-right: 30px;
                }
                @media #{$lg} {
                    margin-right: 22px;
                }
                &:last-child{
                    margin-right: 0;
                }
                &.has-dropdown{
                    & > a{
                        padding-right: 14px;
                        &::after{
                            position: absolute;
                            content: '\f107';
                            right: -4px;
                            top: 0;
                            font-size: 14px;
                            font-size: 14px;
                            color: var(--tp-grey-2);
                            font-family: "Font Awesome 6 Pro";
                        }
                    }
                }
                & > a{
                    color: var(--tp-heading-primary);
                    position: relative;
                    font-size: 16px;
                    font-weight: var(--tp-fw-medium);
                }
                & .active{
                    color: var(--tp-theme-1);
                }
                &:hover .sub-menu {
                    visibility: visible;
                    opacity: 1;
                    top: 120%;
                }
                & .sub-menu{
                    position: absolute;
                    left: 0;
                    top: 110%;
                    min-width: 220px;
                    background-color: var(--tp-common-white);
                    opacity: 0;
                    visibility: hidden;
                    z-index: 99;
                    -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
                    -moz-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
                    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
                    padding: 20px 0px 15px;
                    transition: 0.3s;
                    text-align: left;
                    border-top: 4px solid var(--tp-theme-1);
                    & li{
                        margin: 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        & a{
                            font-size: 16px;
                            font-weight: 400;
                            display: block;
                            padding: 5px 25px;
                            transition: 0.3s;
                            color: #000;
                            &::before{
                                display: none;
                            }
                        }
                        & .active {
                            color: var(--tp-theme-1);
                        }
                        &:hover > a{
                            color: var(--tp-theme-1);
                        }
                        & .sub-menu{
                            left: 120%;
                            top: 0;
                            visibility: hidden;
                            opacity: 0;
                        }
                        &:hover > .sub-menu{
                            left: 100%;
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
                &:hover  a::before {
                    width: 100%;
                    left: 0;
                    right: auto;
                }
            }
        }
    }
}
.menu-padding{
    padding-left: 40px;
}
//header info
.header-info{
    display: flex;
    & .info-item{
        & span{
            font-size: 12px;
            display: inline-block;
            font-weight: var(--tp-fw-sbold);
        }
        & h5{
            font-size: 14px;
            color: var(--tp-common-white);
        }
        &-right{
            margin-right: 40px;
            @media #{$xl} {
                margin-right: 20px;
            }
        }
    }
}
//header
.header{
    &__area{
        &-3{
            position: absolute;
            z-index: 222;
            width: 100%;
        }
    }
    &__top{
        padding: 10px 0;
        &-info{
            display: flex;
            justify-content: end;
            & p{
                font-size: 14px;
                color: var(--tp-common-white);
                margin-bottom: 0;
                & a{
                    text-decoration: underline;
                }
            }
            &-text{
                margin-right: 50px;
                position: relative;
                &::before{
                    position: absolute;
                    content: '';
                    width: 2px;
                    height: 10px;
                    background: #ff7d42;
                    right: -25px;
                    top: 8px;
                    @media #{$md} {
                        display: none;
                    }
                }
                & span{
                    font-weight: var(--tp-fw-sbold);
                }
            }
            @media #{$md} {
                display: block;
            }
        }
        &-right{
            display: flex;
            justify-content: end;
        }
        &-2{
            @media #{$sm,$xs} {
                display: none;
            }
        }
    }
    &__sm-links{
        position: relative;
        margin-right: 60px;
        &::before{
            position: absolute;
            content: '';
            width: 2px;
            height: 10px;
            background: #ff7d42;
            right: -40px;
            top: 8px;
            @media #{$md} {
                right: -15px;
            }
        }
        & a{
            font-size: 16px;
            font-weight: var(--tp-fw-sbold);
            color: var(--tp-common-white);
            margin-right: 35px;
            @media #{$md} {
                margin-right: 15px;
            }
            &:last-child{
                margin-right: 0;
            }
        }
        @media #{$md} {
            margin-right: 15px;
        }
    }
    &__lang{
        & .nice-select{
            height:  auto;
            background: transparent;
            border: 0;
            line-height: 25px;
            color: var(--tp-common-white);
            font-weight: var(--tp-fw-sbold);
            &::after{
                width: 7px;
                height: 7px;
                border-color: var(--tp-common-white);
            }
        }
        & .option{
            color: var(--tp-heading-primary);
            &:hover,
            &.selected.focus{
                color: var(--tp-theme-1);
            }
        }
    }
    &__smcontact{
        display: flex;
        justify-content: end;
    }
    &__inner{
        padding: 20px 0;
        &-2{
            @media #{$sm,$xs} {
                display: none;
            }
        }
    }
    &__smcontact-list{
        display: flex;
        color: var(--tp-common-white);
        position: relative;
        transition: .3s;
        margin-right: 30px;
        align-items: center;
        &:last-child{
            margin-right: 0;
        }
        & .sm-clist{
            &__icon{
                & i{
                    font-size: 30px;
                    color: var(--tp-theme-1);
                    transition: .3s;
                    margin-right: 10px;
                }
                &-2{
                    & i{
                        font-size: 15px;
                        color: var(--tp-grey-3);
                        margin-left: 30px;
                    }
                }
            }
            &__text{
                span{
                    font-size: 14px;
                    color: var(--tp-grey-3);
                    display: inline-block;
                }
                & h4{
                    font-size: 18px;
                    line-height: 1.3;
                    margin-bottom: 0;
                }
                &-2{
                    & h4{
                        font-size: 15px;
                    }
                }
            }
        }
        &-df{
            background: var(--tp-common-white);
            padding: 5px 20px;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
            margin-top: -20px;
            box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
            transition: .3s;
            @media #{$lg} {
                margin-top: 0;
                border-top-right-radius: 6px;
                border-top-left-radius: 6px;
            }
            &:hover{
                background: var(--tp-theme-1);
                & .sm-clist__text-2 span, h4{
                    color: var(--tp-common-white);
                }
                & .sm-clist__icon-2 i{
                    color: var(--tp-common-white);
                }
            }
        }
        &-3{
            background: var(--tp-common-white);
            width: 190px;
            height: 65px;
            border-radius: 6px;
            padding: 10px 15px;
            box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
            transition: .3s;
            margin: 0 auto;
            @media #{$xs} {
                display: none;
            }
            &:hover{
                background: var(--tp-heading-primary);
                & .sm-clist__text-2 span, h4{
                    color: var(--tp-common-white);
                }
                & .sm-clist__icon-2 i{
                    color: var(--tp-common-white);
                    margin-right: 0;
                }
            }
        }
    }
    &__inner-logo{
        padding: 20px 30px;
        background: var(--tp-common-white);
        width: 175px;
        height: 80px;
        border-radius: 6px;
        position: relative;
        z-index: 1;
        margin-top: -40px;
        @media #{$lg} {
            margin-top: 0;
        }
        @media #{$md,$xs,$sm} {
            display: none;
        }
        &::after{
            position: absolute;
            content: '';
            bottom: -5px;
            left: auto;
            right: 0;
            width: 100%;
            height: 10%;
            background: var(--tp-theme-1);
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            z-index: -1;
        }
    }
    &__sm-action{
        display: flex;
        justify-content: end;
        &-item {
            margin-right: 40px;
            &:last-child{
                margin-right: 0;
            }
            & i{
                font-size: 15px;
                color: var(--tp-common-white);
            }
        }
        & .right-border{
            position: relative;
            &::before{
                position: absolute;
                content: '';
                width: 2px;
                height: 10px;
                background: var(--tp-grey-3);
                right: -25px;
                top: 8px;
            }
        }
    }
    &__bottom{
        &-2{
            @media #{$md,$sm,$xs} {
                padding: 10px 0;
            }
        }
    }
    &__side{
        display: flex;
        align-items: center;     
        @media #{$xs} {
            justify-content: space-between;
        }
        &-logo{}
        &-icon{
            margin-right: 30px;
            height: 60px;
            width: 60px;
            background: var(--tp-common-white);
            border-radius: 50%;
            margin-right: 50px;
            padding: 14px 0;
            cursor: pointer;
            & span{
                height: 2px;
                width: 15px;
                display: block;
                background: var(--tp-heading-primary);
                margin: 6px auto;
                transition: .3s;
            }
            & span.bar2 {
                width: 30px;
            }
            &:hover span, .basic-bar span.bar2 {
                width: 30px;
            }
        }
    }
    &__logo{
        &-overlay{
            position: relative;
            width: 255px;
            padding: 10px 30px;
            @media #{$lg,$md,$sm,$xs} {
                padding: 0;
                width: 100%;
            }
            & img{
                max-width: 100%;
            }
        }
    }
}
//menu counter
.menu-counter{
    counter-reset: count;
    & > nav > ul > li::before {
        color: var(--tp-grey-2);
        font-size: 14px;
        counter-increment: count;
        content: "0" counter(count);
        transition: all 500ms ease;
        display: block;
    }
    & > nav > ul > li:hover::before{
        color: var(--tp-theme-1);
    }
}
.lang-block {
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media only screen and (min-width: 992px) {
    .lang-block {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex;
        border-right: 1px solid #eee;
        border-left: 1px solid #eee;
    }
}

.lang-icon {
    display: none;
    position: relative;
    top: 2px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    overflow: hidden
}

@media only screen and (min-width: 1366px) {
    .lang-icon {
        display:initial
    }
}

.lang-select {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none
}

.lang-select__item--active {
    cursor: pointer;
    position: relative;
    -webkit-transition: color .3s;
    -o-transition: color .3s;
    transition: color .3s;
    padding: 35px 10px 40px 30px;
    min-width: 85px;
    margin-bottom: 0;
}

@media only screen and (min-width: 1366px) {
    .lang-select__item--active {
        min-width:100px
    }
}

@media only screen and (min-width: 1366px) {
    .lang-select__item--active {
        min-width:111px
    }
}

.lang-select__item--active span {
    position: relative;
    display: inline-block;
    color: #333;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    -webkit-transition: color .3s;
    -o-transition: color .3s;
    transition: color .3s
}

.lang-select__item--active span::after,.lang-select__item--active span::before {
    content: "";
    display: block;
    width: 6px;
    height: 2px;
    background: #333;
    position: absolute;
    top: 8px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    pointer-events: none
}

.lang-select__item--active span::before {
    right: -15px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.lang-select__item--active span::after {
    right: -18px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.lang-select__item--active:hover span::after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.lang-select__item--active:hover span::before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.lang-select__sub-list {
    margin: 0;
    padding: 12px 30px 18px 30px;
    list-style: none;
    background: #31373f;
    position: absolute;
    top: 100%;
    left: -10px;
    -webkit-transform: translateY(15px);
    -ms-transform: translateY(15px);
    transform: translateY(15px);
    width: 126px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s
}

.lang-select__sub-list::before {
    content: "";
    display: inline-block;
    border: 15px solid transparent;
    position: absolute;
    top: -15px;
    right: 33px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

.lang-select__sub-list li a {
    position: relative;
    display: inline-block;
    color: #fff;
    font-size: 14px;
    line-height: 36px;
    font-weight: 400;
    text-decoration: none;
    -webkit-transition: color .3s;
    -o-transition: color .3s;
    transition: color .3s
}

.lang-select__sub-list li a:hover {
    color: #c4c4c4
}

.lang-select__item--active:hover .lang-select__sub-list {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
    opacity: 1;
    visibility: visible
}

.lang-select__sub-list li a::after {
    content: "";
    display: block;
    width: 0%;
    height: 2px;
    background: var(--tp-theme-1);
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transition: width .3s;
    -o-transition: width .3s;
    transition: width .3s
}

.lang-select__sub-list li a:hover::after {
    width: 100%
}

.lang-select--white .lang-select__item--active span {
    color: #fff
}

.lang-select--white .lang-select__item--active span::after,.lang-select--white .lang-select__item--active span::before {
    background: #fff
}