@use '../utils' as *;

/*----------------------------------------*/
/*  03. SLIDER CSS START
/*----------------------------------------*/
//slider home 1
.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}
.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
}
.sliderm-height{
    height: 809px;
	@media #{$md,$sm} {
		height: 390px;
	}
	@media #{$xs} {
		height: 390px;
	}
}
.slide-bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
	z-index: 0;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	// transition: all 10.5s cubic-bezier(0, 0, 0.2, 1);
	mix-blend-mode: multiply;
	.video_bg {
		width: 100%;
		height: auto;
	  }
}
.item-slider{
	position: relative;
	display: flex;
	align-items: center;
	
}
.slider-contant{
	position: relative;
	z-index: 1;
	display: flex;
	@media #{$sm,$xs} {
		margin-top: 75px;
	}
	.slider-contant-box{
		max-width: 80%;
		@media #{$md,$sm,$xs} {
			max-width: 90%;
		}
	}
	& span{
		font-size: 18px;
		display: inline-block;
		color: var(--tp-common-white);
		@media #{$md,$sm,$xs} {
			font-size: 14px;
		}
	}
	& .slider-title{
		font-size: 60px;
		color: var(--tp-common-white);
		text-transform: uppercase;
		font-weight: 600;
		line-height: 1;
		text-align: inherit;
		@media #{$xl,$lg} {
			font-size: 45px;
		}
		@media #{$md,$sm,$xs} {
			font-size: 20px;
		}
	}
}
.swiper-slide-active .slider-contant span,.swiper-slide-active .slider-contant .slider-title, .swiper-slide-active .slider-button a {
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-name: fadeInUp2;
	animation-name: fadeInUp2;
}
.swiper-slide-active{
	& .slider-contant{
		& span {
			-webkit-animation-delay: .3s;
			animation-delay: .3s;
			-webkit-animation-duration: .3s;
			animation-duration: .3s;
		}
		& .slider-title{
			-webkit-animation-delay: .5s;
			animation-delay: .5s;
			-webkit-animation-duration: .5s;
			animation-duration: .5s;
		}
	}
	& .slider-button{
		& a.tp-btn{
			-webkit-animation-delay: .7s;
			animation-delay: .7s;
			-webkit-animation-duration: .7s;
			animation-duration: .7s;
		}
		& a.tp-btn-2{
			-webkit-animation-delay: .9s;
			animation-delay: .9s;
			-webkit-animation-duration: .9s;
			animation-duration: .9s;
		}
	}
	& .slide-bg{
		transform: scale(1.2);
	}
}

//slider main home 2
.slider{
	&__area{
		position: relative;
		padding: 0;
	}
	&__d-info{
		margin-right: 160px;
		@media #{$lg,$md,$sm,$xs} {
			margin-right: 0px;
		}
		& p{
			font-size: 18px;
			line-height: 26px;
		}
	}
	&__d-title{
		font-size: 120px;
		line-height: .9;
		margin-bottom: 20px;
		font-weight: 600;
		text-transform: uppercase;
		@media #{$lg} {
			font-size: 85px;
		}
		@media #{$md} {
			font-size: 68px;
		}
		@media #{$sm,$xs} {
			font-size: 50px;
		}
	}
	&__item{
		position: relative;
		z-index: 1;
	}
	&__circle-shape{
		height: 500px;
		width: 500px;
		position: absolute;
		right: 5%;
		z-index: 1;
		border-radius: 50%;
		top: 50%;
		transition: 1.2s;
		transform: scale(1);
		background: var(--tp-common-white);
		transform: translateY(-50%);
		z-index: -1;
		@media #{$md} {
			height: 300px;
			width: 300px;
			right: 0%;
			top: 48%;
		}
		@media #{$xs,$sm} {
			display: none;
		}
	}
	&-button{
		& a{
			@media #{$xs} {
				margin-right: 0;
				margin-top: 20px;
			}
		}
	}
}
.swiper-slide-active .slider__d-info p, .swiper-slide-active .slider__d-info .slider__d-title, .swiper-slide-active .slider__d-info .subscribe-form {
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}
.swiper-slide-active{
	& .slider__d-info{
		& .slider__d-title{
			-webkit-animation-delay: .3s;
			animation-delay: .3s;
			-webkit-animation-duration: .3s;
			animation-duration: .3s;
		}
		& p{
			-webkit-animation-delay: .5s;
			animation-delay: .5s;
			-webkit-animation-duration: .5s;
			animation-duration: .5s;
		}
		& .subscribe-form{
			-webkit-animation-delay: .7s;
			animation-delay: .7s;
			-webkit-animation-duration: .7s;
			animation-duration: .7s;
		}
	}
}
.main-slider-nav {
	overflow: hidden;
}

//main-slider nav
.main-slider-dot{
	margin-top: -96px;
	@media #{$lg} {
		margin-top: -96px;
	}
	@media #{$md} {
		margin-top: -115px;
	}
	@media #{$sm} {
		margin-top: -130px;
	}
	@media #{$xs} {
		margin-top: 0;
	}
}
.swiper-slide-thumb-active .sm-button{
	opacity: 1;
}
.sm-button{
	display: flex;
	width: 100%;
	color: var(--tp-common-white);
	padding-right: 30px;
	padding-bottom: 40px;
	position: relative;
	cursor: pointer;
	opacity: 0.6;
	transition: .3s;
	@media #{$xl,$lg} {
		padding-right: 15px;
	}
	@media #{$md} {
		padding-right: 10px;
	}
	@media #{$sm,$xs} {
		display: block;
	}
	@media #{$sm} {
		padding-right: 0;
		padding-bottom: 25px;
	}
	@media #{$xs} {
		display: none;
	}
	&::before{
		border-color: var(--tp-theme-1);
		position: absolute;
		content: '';
		background: var(--tp-theme-1);
		width: 20%;
		height: 4px;
		bottom: 2px;
		visibility: hidden;
		opacity: 0;
		transition: .3s;
	}
	&:hover {
		border-color: var(--tp-theme-1);
		opacity: 1;
	}
	&:hover::before{
		width: 100%;
		visibility: visible;
		opacity: 1;
	}
}
.main-slider-dot .swiper-slide-thumb-active .sm-button::before {
	width: 100%;
	visibility: visible;
	opacity: 1;
}
.main-slider-dot .swiper-slide-thumb-active .sm-button{
	filter:grayscale(0);
}

.history {
    overflow: hidden
}

.slider-holder {
    padding: 0
}

@media only screen and (min-width: 576px) {
    .slider-holder {
        overflow:hidden;
        margin-left: calc((100% - 510px)/ 2)
    }
}

@media only screen and (min-width: 768px) {
    .slider-holder {
        margin-left:calc((100% - 690px)/ 2)
    }
}

@media only screen and (min-width: 992px) {
    .slider-holder {
        margin-left:calc((100% - 930px)/ 2)
    }
}

@media only screen and (min-width: 1200px) {
    .slider-holder {
        margin-left:calc((100% - 1170px)/ 2)
    }
}
@media only screen and (min-width: 1600px) {
    .slider-holder {
        margin-left:calc((100% - 1320px)/ 2)
    }
}

.steps-slider__nav {
    margin-bottom: -14px;
	position: relative;
    padding: 0;
    margin: 0;
    line-height: initial;
    display: -webkit-inline-box!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.steps-slide__title {
    margin-bottom: 16px;
	font-size: 18px;
	font-weight: 300;
	line-height: 1.5;
}

.steps-slide__date {
    padding: 0 15px;
    line-height: 1
}

@media only screen and (min-width: 576px) {
    .steps-slide__date {
        padding:0
    }
}

.steps-slide__date span {
    display: inline-block;
    color: var(--global--color-primary);
    font-weight: 700;
    letter-spacing: -.050em
}

.steps-slide__date span:first-of-type {
    font-size: 60px;
    margin-right: 8px;
    text-shadow: 4px 0 #dbdce0
}

.steps-slide__date span:last-of-type {
    font-size: 30px
}

.steps-slide__top {
    position: relative;
    border-bottom: 2px solid #dbdce0;
    padding-bottom: 45px
}

.steps-slide__top::after {
    content: "";
    display: block;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: url(../../media/slide_img.png) center center no-repeat;
    background-size: contain;
    position: absolute;
    left: 15px;
    bottom: -17px
}

@media only screen and (min-width: 576px) {
    .steps-slide__top::after {
        left:0
    }
}

.steps-slide__lower {
    width: 100%;
    background: #fff;
    padding: 37px 40px 31px;
    position: relative;
	margin-top: 40px;
	min-height: 200px;
}
.steps-slide__lower:before {
    content: '';
    width: 28px;
    height: 18px;
    background: #fff;
    margin-top: -18px;
    -webkit-clip-path: polygon(0 0,100% 100%,100% 100%,0 100%,0 0);
    clip-path: polygon(0 0,100% 100%,100% 100%,0 100%,0 0);
    position: absolute;
    top: 0;
    left: 0;
}

@media only screen and (min-width: 576px) {
    .steps-slide__lower {
        padding:42px  40px 31px
    }
}

@media only screen and (min-width: 768px) {
    .steps-slide__lower {
        max-width:370px
    }
}

@media only screen and (min-width: 576px) {
    .cooperation--slider .steps-slide__lower {
        max-width:270px
    }
}
.steps-slider__nav span {
    position: relative;
    padding: 15px 0 15px 0;
    cursor: pointer;
    width: 57px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    line-height: initial;
	display:inline-block ;
}
.steps-slider__nav span:not(:last-of-type) {
    margin-right: 6px;
}
.steps-slider__nav span:after{
    position: absolute;
    top: 15px;
    left: 0;
    font-size: 0;
    height: 1px;
    width: 57px;
    padding: 0;
    border: none;
    outline: 0;
    background-color: #ddd;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    cursor: pointer;
	content: '';
}
.steps-slider__nav span.swiper-pagination-bullet-active:after,.steps-slider__nav span:hover:after{
	background-color: var(--tp-theme-1);
    height: 5px;
    top: 11px;
}

/*------------------------------------*\
	#Slider
\*------------------------------------*/
.slider {
	padding-top   : 0;
	padding-bottom: 0;
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.slider .slide {
	padding-top      : 0;
	height           : 650px;
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
}

.slider .slide .slide-content {
	display              : -webkit-box;
	display              : -ms-flexbox;
	display              : flex;
	-webkit-box-pack     : center;
	-ms-flex-pack        : center;
	justify-content      : center;
	-webkit-box-orient   : vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction   : column;
	flex-direction       : column;
}

@media screen and (max-width: 991.98px) {
	.slider .slide .slide-content {
		-webkit-box-align: center;
		-ms-flex-align   : center;
		align-items      : center;
	}
}

.slider .slide .slide-content .slide-subheadline {
	font-family   : var(--global--font-body);
	font-weight   : 700;
	font-size     : 16px;
	line-height   : 1;
	color         : var(--global--color-gray);
	margin-bottom : 20px;
	text-transform: capitalize;
}

@media screen and (max-width: 991.98px) {
	.slider .slide .slide-content .slide-subheadline {
		text-align : center;
		line-height: 1.5;
	}
}

.slider .slide .slide-content .slide-headline {
	font-family   : var(--global--font-heading);
	font-weight   : 700;
	font-size     : 75px;
	line-height   : 75px;
	color         : var(--global--color-white);
	margin-bottom : 24px;
	text-transform: capitalize;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.slider .slide .slide-content .slide-headline {
		font-size  : 60px;
		line-height: 1.3;
	}
}

@media screen and (max-width: 767.98px) {
	.slider .slide .slide-content .slide-headline {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media screen and (max-width: 991.98px) {
	.slider .slide .slide-content .slide-headline {
		text-align: center;
	}
}

.slider .slide .slide-content .slide-desc {
	font-family  : var(--global--font-body);
	font-weight  : 700;
	font-size    : 18px;
	line-height  : 29px;
	color        : var(--global--color-gray);
	margin-bottom: 41px;
}

@media screen and (max-width: 991.98px) {
	.slider .slide .slide-content .slide-desc {
		text-align: center;
	}
}

.slider .slide .slide-content .slide-action {
	margin-bottom    : 0px;
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
}

@media screen and (max-width: 767.98px) {
	.slider .slide .slide-content .slide-action {
		display              : -webkit-box;
		display              : -ms-flexbox;
		display              : flex;
		-webkit-box-orient   : vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction   : column;
		flex-direction       : column;
		-webkit-box-align    : center;
		-ms-flex-align       : center;
		align-items          : center;
	}
}

.slider .slide .slide-content .slide-action .btn {
	-webkit-box-pack  : justify;
	-ms-flex-pack     : justify;
	justify-content   : space-between;
	border-radius     : 4px;
	padding           : 30px;
	-webkit-box-shadow: 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	box-shadow        : 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	font-family       : var(--global--font-body);
	font-size         : 15px;
	font-weight       : 700;
	display           : -webkit-inline-box;
	display           : -ms-inline-flexbox;
	display           : inline-flex;
	width             : 200px;
	height            : 65px;
	margin-right      : 30px;
}

.slider .slide .slide-content .slide-action .btn:last-child {
	margin-right: 0;
}

@media screen and (max-width: 767.98px) {
	.slider .slide .slide-content .slide-action .btn {
		width        : 190px;
		height       : 50px;
		margin-right : 0;
		margin-bottom: 20px;
	}

	.slider .slide .slide-content .slide-action .btn:last-child {
		margin-bottom: 0;
	}
}

.slider .slide .slide-content .slide-action .btn i {
	font-size   : 12px;
	margin-right: 0;
}

.slider .slide .slide-content .slide-list {
	margin-right: 40px;
}

@media screen and (max-width: 767.98px) {
	.slider .slide .slide-content .slide-list {
		display          : -webkit-box;
		display          : -ms-flexbox;
		display          : flex;
		-ms-flex-wrap    : wrap;
		flex-wrap        : wrap;
		-webkit-box-align: center;
		-ms-flex-align   : center;
		align-items      : center;
		-webkit-box-pack : center;
		-ms-flex-pack    : center;
		justify-content  : center;
		margin-right     : 0;
	}
}

.slider .slide .slide-content .slide-list .icon {
	margin-right: 35px;
	display     : inline-block;
}

.slider .slide .slide-content .slide-list .icon:last-child {
	margin-right: 0;
}

@media screen and (max-width: 767.98px) {
	.slider .slide .slide-content .slide-list .icon {
		margin-right : 0;
		margin       : 0 15px;
		margin-bottom: 15px;
	}

	.slider .slide .slide-content .slide-list .icon:last-child {
		margin-right: 15px;
	}
}

.slider .slide .slide-content .slide-list .icon:hover i {
	-webkit-transform: translateY(-10px);
	-ms-transform    : translateY(-10px);
	transform        : translateY(-10px);
}

.slider .slide .slide-content .slide-list .icon i {
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.slider .slide .slide-content .slide-list .icon i::before {
	margin-left: 0;
	font-size  : 67px;
	color      : var(--global--color-white);
}

.slider .slide .slider-panel-holder {
	display              : -webkit-box;
	display              : -ms-flexbox;
	display              : flex;
	-webkit-box-orient   : vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction   : column;
	flex-direction       : column;
	-webkit-box-flex     : 1;
	-ms-flex-positive    : 1;
	flex-grow            : 1;
	-webkit-box-pack     : center;
	-ms-flex-pack        : center;
	justify-content      : center;
}

@media screen and (max-width: 991.98px) {
	.slider .slide .slider-panel-holder {
		-webkit-box-align: center;
		-ms-flex-align   : center;
		align-items      : center;
	}
}

.slider .slide .slider-panel-holder .slider-panel {
	background-color     : var(--global--color-white);
	position             : relative;
	padding              : 40px;
	display              : -webkit-box;
	display              : -ms-flexbox;
	display              : flex;
	-webkit-box-orient   : vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction   : column;
	flex-direction       : column;
	-webkit-box-align    : center;
	-ms-flex-align       : center;
	align-items          : center;
	text-align           : center;
	-webkit-box-shadow   : 0px 5px 83px 0px rgba(40, 40, 40, 0.08);
	box-shadow           : 0px 5px 83px 0px rgba(40, 40, 40, 0.08);
	border-radius        : 8px;
	max-width            : 270px;
}

@media screen and (max-width: 767.98px) {
	.slider .slide .slider-panel-holder .slider-panel {
		padding: 30px;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.slider .slide .slider-panel-holder .slider-panel {
		padding: 20px;
	}
}

.slider .slide .slider-panel-holder .slider-panel::before {
	content          : '';
	position         : absolute;
	top              : 0;
	left             : 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform    : translateX(-50%);
	transform        : translateX(-50%);
	height           : 4px;
	width            : calc(100% - 80px);
	background-color : var(--global--color-primary);
}

@media screen and (max-width: 767.98px) {
	.slider .slide .slider-panel-holder .slider-panel::before {
		width: calc(100% - 60px);
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.slider .slide .slider-panel-holder .slider-panel::before {
		width: calc(100% - 40px);
	}
}

.slider .slide .slider-panel-holder .slider-panel .panel-icon {
	margin-bottom: 23px;
}

.slider .slide .slider-panel-holder .slider-panel .panel-icon:before {
	font-size  : 75px;
	margin-left: 0;
	color      : var(--global--color-heading);
}

.slider .slide .slider-panel-holder .slider-panel .panel-title {
	font-size     : 19px;
	line-height   : 29px;
	color         : var(--global--color-secondary);
	text-transform: capitalize;
	margin-bottom : 10px;
}

.slider .slide .slider-panel-holder .slider-panel .panel-desc {
	font-size    : 15px;
	line-height  : 25px;
	margin-bottom: 17px;
}

.slider .slide .slider-panel-holder .slider-panel a {
	display           : -webkit-inline-box;
	display           : -ms-inline-flexbox;
	display           : inline-flex;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	width             : 32px;
	height            : 32px;
	background-color  : var(--global--color-primary);
	border-radius     : 50%;
	color             : var(--global--color-white);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.slider .slide .slider-panel-holder .slider-panel a:hover {
	-webkit-transform: translateX(10px);
	-ms-transform    : translateX(10px);
	transform        : translateX(10px);
}

@media screen and (max-width: 991.98px) {
	.slider .carousel-navs .owl-nav {
		display: none;
	}
}

.slider .carousel-navs .owl-nav span {
	font-size: 0;
}

.slider .carousel-navs .owl-nav .owl-next:before,
.slider .carousel-navs .owl-nav .owl-prev:before {
	color             : rgba(255, 255, 255, 0.5);
	font-family       : "Font Awesome 6 Pro" !important;
	font-size         : 44px;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.slider .carousel-navs .owl-nav .owl-prev:before {
	content: "\f177";
}

.slider .carousel-navs .owl-nav .owl-next:before {
	content: "\f178";
}

.slider .carousel-navs .owl-nav .owl-next,
.slider .carousel-navs .owl-nav .owl-prev {
	position         : absolute;
	top              : 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform    : translateY(-50%);
	transform        : translateY(-50%);
}

.slider .carousel-navs .owl-nav .owl-next:hover::before,
.slider .carousel-navs .owl-nav .owl-prev:hover::before {
	color: white;
}

.slider .carousel-navs .owl-nav .owl-next {
	right: 30px;
}

.slider .carousel-navs .owl-nav .owl-prev {
	margin-right: 0;
	left        : 30px;
}

@media screen and (max-width: 991.98px) {
	.slider.slider-1 .slide {
		height        : auto;
		padding-top   : 70px;
		padding-bottom: 110px;
	}
}

@media screen and (max-width: 991.98px) {
	.slider.slider-1 .slide .slide-content {
		margin-bottom: 50px;
	}
}

@media screen and (max-width: 767.98px) {
	.slider.slider-1 .slide .slide-content .slide-list {
		margin-bottom: 25px;
	}
}

@media screen and (min-width: 992px) {
	.slider.slider-2 .carousel-dots {
		display              : -webkit-box;
		display              : -ms-flexbox;
		display              : flex;
		-webkit-box-orient   : vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction   : column;
		flex-direction       : column;
	}
}

@media screen and (min-width: 992px) {
	.slider.slider-2 .carousel-dots .owl-dots {
		padding-right            : var(--bs-gutter-x, 0.75rem);
		padding-left             : var(--bs-gutter-x, 0.75rem);
		margin-right             : auto;
		margin-left              : auto;
		position                 : relative;
		max-width                : 960px;
		bottom                   : auto;
		-webkit-box-ordinal-group: 0;
		-ms-flex-order           : -1;
		order                    : -1;
		-webkit-box-pack         : start;
		-ms-flex-pack            : start;
		justify-content          : flex-start;
		-webkit-transform        : translateY(80px);
		-ms-transform            : translateY(80px);
		transform                : translateY(80px);
		margin-top               : -6px;
	}
}

@media screen and (min-width: 1200px) {
	.slider.slider-2 .carousel-dots .owl-dots {
		max-width: 1140px;
	}
}

.slider.slider-3 .slide-content .slide-headline {
	margin-bottom: 34px;
}

.slider.slider-3 .slide-content .slide-list {
	margin-right : 0;
	margin-bottom: 32px;
}

@media screen and (max-width: 767.98px) {
	.slider.slider-3 .slide-content .slide-list li::before {
		margin-right     : 10px;
		-ms-flex-negative: 0;
		flex-shrink      : 0;
	}
}

@media screen and (max-width: 991.98px) {
	.slider.slider-3 .slide-content .slide-action {
		-webkit-box-pack: center;
		-ms-flex-pack   : center;
		justify-content : center;
	}
}

.slider.slider-3 .slide-content .slide-action .btn:first-child {
	margin-right: 40px;
}

@media screen and (max-width: 767.98px) {
	.slider.slider-3 .slide-content .slide-action .btn:first-child {
		margin-right: 0;
	}
}

.slider.slider-3 .slide-content .slide-action .btn:first-child:hover i {
	color: var(--global--color-white);
}

.slider.slider-3 .slide-content .slide-action .btn:first-child i {
	color             : var(--global--color-heading);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.slider.slider-3 .slide-content .slide-action .btn-video:hover span {
	color: var(--global--color-heading);
}

.slider.slider-3 .slide-content .slide-action .btn-video:hover i {
	background-color: var(--global--color-heading);
	color           : var(--global--color-white);
}

.slider.slider-3 .slide-content .slide-action .btn-video i {
	width       : 65px;
	height      : 65px;
	margin-right: 30px;
}

.slider.slider-3 .slide-content .slide-action .btn-video span {
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	font-family       : var(--global--font-body);
	font-size         : 15px;
	font-weight       : 700;
	color             : var(--global--color-white);
	text-transform    : capitalize;
}