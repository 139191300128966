@use '../utils' as *;

/*----------------------------------------*/
/*  00. Contact CSS START
/*----------------------------------------*/
.contact{
    &__area{
        @include background();
        &-2{
            position: relative;
            z-index: 1;
        }
    }
    &__item{
        &:hover{
            & .contact__icon i{
                background: var(--tp-theme-1);
                color: var(--tp-common-white);
                transform: rotateY(360deg);
            }
        }
    }
    &__icon{
        & i{
            height: 100px;
            width: 100px;
            line-height: 100px;
            font-size: 35px;
            text-align: center;
            background-color: var(--tp-common-white);
            border-radius: 50%;
            color: var(--tp-theme-1);
            box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.08);
            display: inline-block;
            transition: .3s;
        }
    }
    &__title{
        font-size: 24px;
    }
    &__text{
        & p{
            margin-bottom: 0;
            font-size: 16px;
        }
    }
    &__social{
        & a{
            height: 50px;
            width: 50px;
            line-height: 50px;
            text-align: center;
            color: var(--tp-heading-primary);
            background: var(--tp-common-white);
            border-radius: 6px;
            box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.08);
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;
            font-size: 15px;
            transition: .3s;
            &:hover{
                background: var(--tp-theme-1);
                color: var(--tp-common-white);
            }
        }
    }
    &__form{
        background: var(--tp-common-white);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        padding: 80PX 60px;
        @media #{$sm,$xs} {
            padding: 80px 0;
        }
        & .contact-filed {
            position: relative;
            &::after{
                content: "\f007";
                position: absolute;
                left: 30px;
                top: 18px;
                font-family: "Font Awesome 5 Pro";
                color: var(--tp-heading-primary);
            }
            &.contact-icon-mail::after{
                content: "\f0e0";
            }
            &.contact-icon-message::after{
                content: "\f303";
            }
            &.contact-icon-website::after{
                content: "\f0ac";
            }
            & input  {
                width: 100%;
                height: 60px;
                border: 1px solid transparent;
                padding-left: 55px;
                padding-right: 30px;
                color: var(--tp-heading-primary);
                border-radius: 6px;
                font-weight: 500;
                transition: .3s;
                background: #f9f9f9;
                &::-moz-placeholder {
                    color: #aeaeae;
                    font-size: 14px;
                    opacity: 1;
                }
                &::placeholder {
                    color: #aeaeae;
                    font-size: 14px;
                    opacity: 1;
                }
                &:focus{
                    border-color: var(--tp-theme-1);
                }
            }
            & textarea{
                width: 100%;
                height: 250px;
                border: 1px solid transparent;
                padding-left: 55px;
                padding-right: 30px;
                padding-top: 15px;
                color: var(--tp-heading-primary);
                font-weight: 500;
                transition: .3s;
                border-radius: 6px;
                box-shadow: none;
                outline: none;
                background: #f9f9f9;
                resize: none;
                &::-moz-placeholder {
                    color: #aeaeae;
                    font-size: 14px;
                    opacity: 1;
                }
                &::placeholder {
                    color: #aeaeae;
                    font-size: 14px;
                    opacity: 1;
                }
                &:focus{
                    border-color: var(--tp-theme-1);
                }
            }
        }
        &-agree{
            padding-left: 5px;
            & input{
                margin: 0;
                appearance: none;
                -moz-appearance: none;
                display: block;
                width: 14px;
                height: 14px;
                background: var(--tp-common-white);
                border: 1px solid #b9bac1;
                outline: none;
                @include border-radius(4px);
                &:checked{
                    position: relative;
                    background-color: #2b4eff;
                    border-color: transparent;
                    &::after{
                        box-sizing: border-box;
                        content: '\f00c';
                        position: absolute;
                        font-family: var(--tp-ff-fontawesome);
                        font-size: 10px;
                        color: var(--tp-common-white);
                        top: 46%;
                        left: 50%;
                        @include transform(translate(-50%, -50%));
                    }
                }
                &:hover{
                    cursor: pointer;
                }
            }
            & label{
                padding-left: 8px;
                color: #53545b;
                font-size: 16px;
                & a{
                    color: var(--tp-heading-primary);
                    font-weight: 600;
                    padding-left: 4px;
                    &:hover{
                        color: var(--tp-theme-1);
                    }
                }
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
    &__map{
        line-height: 0;
        position: relative;
        margin-top: -60px;
        &-wrap iframe {
            height: 800px;
            width: 100%;
            @media #{$md} {
                height: 600px;
            }
            @media #{$sm,$xs} {
                height: 500px;
            }
        }
        &-icon{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            text-align: center;
            & i{
                font-size: 80px;
                color: var(--tp-theme-1);
                position: absolute;
                left: 48.5%;
                top: -14.5%;
            }
        }
    }
}