@use '../utils' as *;

/*----------------------------------------*/
/*  00. SPACING CSS START
/*----------------------------------------*/


.pt-250{
    padding-top: 250px;
}
.pb-205{
    padding-bottom: 205px;
}