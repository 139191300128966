///
/// The DROPDOWN (mega) mixin allows for full-width mega dropdown
/// menus. Call this mixin on a list item, not on nav.primary.
/// Note that the simple dropdown component is a dependency of
/// DROPDOWN (mega). The mega dropdown is positioned to header.primary
/// instead of its parent list item.
///

@mixin dropdown-mega() {
    position: static;

    @include at(lg) {
        .dropdown {
            left: 0;
            top: 100%;
            width: 100%;
            .menu {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                margin-left: auto;
                margin-right: auto;
                max-width: 1168px;
                > * {
                    border-left: 1px solid #777;
                    flex-basis: 0;
                    flex-grow: 1;
                    padding: 0 3rem;
                }
                > *:first-child {
                    border-left: none;
                    padding-left: 0;
                }
                > *:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

.mega {
    @include dropdown-mega;
}
