@use '../utils' as *;

/*----------------------------------------*/
/*  00. SERVICES CSS START
/*----------------------------------------*/

.service_tab_section{
	position: relative;
	z-index: 1;
}
.sm-services{
	&__icon{
		margin-right: 15px;
		& i{
			font-size: 50px;
			color: var(--tp-theme-1);
			transition: .3s;
		}
	}
	&__text{
		span{
			font-size: 14px;
			color: var(--tp-grey-3);
			display: inline-block;
		}
		& h4{
			color: var(--tp-common-white);
			font-size: 20px;
			line-height: 1.3;
			margin-bottom: 0;
			@media #{$xl} {
				font-size: 18px;
			}
			@media #{$sm,$xs} {
				font-size: 18px;
			}
		}
	}
}
//single service
.single-services{
	padding: 35px 40px;
	background: var(--tp-common-white);
	border-radius: 6px;
	& h5{
		font-size: 24px;
		line-height: 1.3;
		margin-bottom: 20px;
	}
	& .services-list{
		& ul{
			li{
				margin-bottom: 12px;
				list-style: none;
				& span{
					font-size: 16px;
					display: inline-block;
				}
			}
		}
	}
	& .sr-button{
		padding-top: 15px;
		border-top: 1px solid var(--tp-border-2);
		& a{
			font-size: 14px;
			font-weight: var(--tp-fw-medium);
			&:hover{
				color: var(--tp-theme-1);
			}
		}
	}
}
.services__slider{
	position: relative;
	margin-right: -370px;
	@media #{$xs,$sm,$md} {
		margin-right: 0;
	}
}
.ser__text{
	& a{
		color: var(--tp-heading-primary);
		text-decoration: underline;
		font-weight: var(--tp-fw-medium);
	}
}
// sm-services css
.sm-services{
	&__lists{
		transform: translateY(0px);
	}
	&__item{
		.flip-card-front {
			background-color: transparent;
			width: 100%;
			height: 250px;
			border-radius: 6px;
			& .flip-card-icon{
				& i{
					font-size: 80px;
					color: var(--tp-heading-primary);
					display: block;
					& svg{
						fill: var(--tp-heading-primary);
						width: 80px;
						height: 80px;
					}
				}
				&-2{
					& i{
						color: var(--tp-theme-1);
					}
				}
			}
			& .flip-card-title{
				font-size: 20px;
				&-2{
					margin-bottom: 15px;
				}
			}
		}
		  .flip-card-back {
			background-color: transparent;
			width: 100%;
			height: 250px;
			border-radius: 6px;
			& .flip-card-icon{
				& i{
					font-size: 80px;
					color: var(--tp-common-white);
					& svg{
						width: 80px;
						height: 80px;
						fill: var(--tp-common-white);
					}
				}
			}
			& .flip-card-title{
				font-size: 20px;
				color: var(--tp-common-white);
			}
			& .flip-card-omore{
				& i{
					color: var(--tp-common-white);
				}
				& p{
					font-size: 14px;
					line-height: 24px;
				}
			}
		  }    
		  .flip-card-inner {
			position: relative;
			width: 70%;
			height: 100%;
			text-align: center;
			transition: transform 0.6s;
			transform-style: preserve-3d;
			box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
			@media #{$md,$xs} {
				width: 100%;
			}
			&-2{
				box-shadow: none;
				& p{
					font-size: 14px;
					line-height: 24px;
				}
			}
		  }
		  .flip-card:hover .flip-card-inner {
			transform: rotateY(180deg);
		  }
		  .flip-card-front, .flip-card-back {
			position: absolute;
			width: 100%;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
		  }
		  .flip-card-front {
			background-color: #fff;
			color: black;
			border-radius: 6px;
			padding: 15px 0;
			&-2{
				padding: 35px 40px;
				height: 260px;
			}
		  }
		  .flip-card-back {
			background-color: var(--tp-theme-1);
			color: var(--tp-common-white);
			transform: rotateY(180deg);
			border-radius: 6px;
			padding: 15px 0;
			position: relative;
			&-2{
				padding: 35px 40px;
				height: 260px;
			}
			& span{
				display: inline-block;
				font-weight: var(--tp-fw-medium);
			}
			& p{
				color: var(--tp-common-white);
			}
			.flip-card-bg{
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: -1;
				& img{
					width: 100%;
					height: 100%;
				}
			}
		}
		@media #{$md,$xs} {
			margin-bottom: 30px;
		}
	}
	&__info{
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media #{$md,$sm,$xs} {
			display: block;
		}
		& h5{
			font-size: 24px;
			color: var(--tp-common-white);
			@media #{$lg} {
				font-size: 22px;
			}
			@media #{$md,$sm,$xs} {
				font-size: 22px;
				margin-bottom: 20px;
			}
		}
	}
	&__more{
		& a{
			font-size: 16px;
			color: var(--tp-common-white);
			transition: .3s;
			& i{
				margin-left: 5px;
			}
			&:hover{
				color: var(--tp-theme-1);
			}
		}
	}
}
//services 
.services{
	&__area{
		@include background ();
		position: relative;
		z-index: 1;
	}
	&__item{
		padding: 50px 45px;
		background: var(--tp-common-white);
		border-radius: 6px;
		@media #{$xl} {
			padding: 50px 25px;
		}
		@media #{$lg} {
			padding: 50px 15px;
		}
		@media #{$md} {
			padding: 50px 30px;
		}
		& .ser{
			&__icon{
				& i{
					font-size: 60px;
					color: var(--tp-theme-1);
				}
			}
			&__title{
				font-size: 20px;
				line-height: 1.4;
				@media #{$md} {
					font-size: 18px;
				}
				& br{
					@media #{$md} {
						display: none;
					}
				}
				&:hover{
					color: var(--tp-theme-1);
				}
			}
			&__more-option{
				& a{
					font-size: 14px;
					color: var(--tp-theme-1);
					font-weight: var(--tp-fw-sbold);
					display: inline-block;
					& i{
						margin-left: 10px;
					}
				}
			}
		}
		& p{
			margin-bottom: 0;
			font-size: 14px;
			line-height: 22px;
		}
		&-tp{
			position: relative;
			box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.03);
			&::after{
				position: absolute;
				content: '';
				left: 15px;
				right: 15px;
				background-color: var(--tp-theme-1);
				border-radius: 6px;
				height: 100%;
				bottom: 0;
				z-index: -1;
				visibility: hidden;
				opacity: 0;
			}
			&::before{
				position: absolute;
				content: '';
				left: 0;
				background-color: var(--tp-common-white);
				border-radius: 6px;
				height: 100%;
				width: 100%;
				top: 0;
				z-index: -1;
				visibility: hidden;
				opacity: 0;
			}
			&:hover{
				&::after,
				&::before{
					visibility: visible;
					opacity: 1;
				}

				&::after{
					bottom: -15px;
				}

				&::before{
					top: -25px;
				}
			}
		}
		&-grid{
			position: relative;
			border: 2px solid var(--tp-border-2);
			&::after{
				position: absolute;
				content: '';
				left: 15px;
				right: 15px;
				background-color: var(--tp-theme-1);
				border-radius: 6px;
				height: 100%;
				bottom: 0;
				z-index: -1;
				visibility: hidden;
				opacity: 0;
			}
			&::before{
				position: absolute;
				content: '';
				left: -2px;
				background-color: var(--tp-common-white);
				border-radius: 6px;
				height: 100%;
				width: 101.5%;
				top: 0;
				z-index: -1;
				visibility: hidden;
				opacity: 0;
				border: 2px solid var(--tp-border-2);
				transition: .3s;
			}
			&:hover{
				border-top-color: transparent;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				&::after,
				&::before{
					visibility: visible;
					opacity: 1;
				}
				&::after{
					bottom: -15px;
				}
				&::before{
					top: -25px;
				}
			}
		}
	}
}
//related services
.related{
	&__services{
		position: relative;
		&-title{
			font-size: 20px
		}
	}
}

// cta css
.cta{
	&__area{
		@include background();
		position: relative;
		background-attachment: fixed;
		z-index: 1;
		&::before{
			position: absolute;
			content: "";
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(52, 58, 64, 0.6);
			z-index: -1;
		}
	}
	&__title{
		font-size: 60px;
		color: var(--tp-common-white);
		& br{
			@media #{$lg,$sm,$xs} {
				display: none;
			}
		}
		@media #{$md} {
			font-size: 40px;
		}
		@media #{$sm,$xs} {
			font-size: 30px;
		}
	}
	&__button{
		& a{
			@media #{$xs} {
				margin-right: 0;
				margin-top: 20px;
			}
		}
	}
}
.hide{
	display: none;
}
/* history css */
.history{
    &__area{
        position: relative;
        z-index: 1;
    }
    &__right{
        position: relative;
        &-bg{
            position: absolute;
            width: 35%;
            top: 0;
            height: 100%;
            right: 0;
            z-index: -1;
            @media #{$md,$sm,$xs}{
                display: none;
            }
            & img{
                width: 100%;
                height: 100%;
            }
        }
    }
    &__item{
        & .sm-item{
            &-thumb{
                position: relative;
				overflow: hidden;
                 & img{
					border-top-left-radius: 6px;
					border-top-right-radius: 6px;
					transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
                 }
            }
            &-tag{
                position: absolute;
                bottom: 0;
                left: 30px;
                & span{
                    width: 70px;
                    height: 30px;
                    line-height: 30px;
                    color: var(--tp-common-white);
                    background: var(--tp-theme-1);
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    display: inline-block;
                    text-align: center;
                }
            }
            &-content{
                padding: 30px;
                background: var(--tp-common-white);
                border-bottom-left-radius: 6px;
                 border-bottom-right-radius: 6px;
                 @media #{$xl} {
                     padding: 30px 20px;
                 }
                & h6{
                 font-size: 20px;
                 transition: .3s;
                 @media #{$xl} {
                     font-size: 18px;
                 }
                 &:hover{
                     color: var(--tp-theme-1);
                 }
                }
                & p{
                	margin-bottom: 0;
					font-size: 14px;
					line-height: 22px;
                }
            }
        }
		&:hover .sm-item-thumb img{
			transform: scale3d(1.1, 1.1, 1.1);
		}
    }
}
//nevigation css
.nevigation{
	&__info{
		display: flex;
		align-items: center;
		& img{
			margin-right: 20px;
		}
		&-2{
			text-align: right;
			& img{
				margin-left: 20px;
				margin-right: 0;
			}
			justify-content: end;
		}
	}
	&__content{
		& p{
			margin-bottom: 0;
		}

	}
	&__title{
		font-size: 18px;
		transition: .3s;
		&:hover{
			color: var(--tp-theme-1);
		}
	}
	&__icon{
		& i{
			font-size: 40px;
			color: var(--tp-theme-1);
		}
	}
}


.contact-trigger {
    position: relative;
    color: #fff;
    background: #003d90;
    padding: 28px 30px 40px 30px;
    width: 100%;
    max-width: 370px;
    -webkit-clip-path: polygon(90% 0,100% 18%,100% 100%,0 100%,0 0);
    clip-path: polygon(90% 0,100% 18%,100% 100%,0 100%,0 0)
}

.contact-trigger--style-2 {
    -webkit-clip-path: polygon(85% 0,100% 10%,100% 100%,0 100%,0 0);
    clip-path: polygon(85% 0,100% 10%,100% 100%,0 100%,0 0)
}

.contact-trigger__img {
    height: 100px;
    width: auto;
    position: absolute;
    bottom: 0;
    right: 2px
}

.contact-trigger__title {
    line-height: 45px;
	color: #fff;
}

.contact-trigger__text {
    margin-top: 13px;
	color: #fff;
}

.contact-trigger .button {
    position: relative;
    z-index: 2;
    margin-top: 20px
}