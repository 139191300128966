@use '../utils' as *;

/*----------------------------------------*/
/*  03. Feature CSS START
/*----------------------------------------*/
//features
.feature{
    &__list{
        & ul{
            & li{
                list-style: none;
                padding: 18px 30px;
                background: var(--tp-grey-5);
                margin-bottom: 20px;
                border-radius: 6px;
                transition: .3s;
                &:last-child{
                    margin-bottom: 0;
                }
                &:hover{
                    background: var(--tp-theme-1);
                    transform: translateX(15px);
                    @media #{$sm,$xs} {
                        transform: translateX(0px);
                        transform: translateY(-5px);
                    }
                    & > a{
                        color: var(--tp-common-white);
                        & i{
                            color: var(--tp-common-white);
                        }
                    }
                }
                & a{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    transition: .3s;
                    color: var(--tp-heading-primary);
                    font-size: 18px;
                    & i{
                        color: var(--tp-grey-6);
                    }
                }
            }
        }
    }
    &__left{
        margin-right: 55px;
        @media #{$md,$sm,$xs} {
            margin-right: 0;
        }
    }
    &__images{
        margin-left: 10px;
        position: relative;
        @media #{$sm,$xs} {
            margin-left: 0;
        }
    }
}

//video -btn
.vide-button{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -65px;
    @media #{$xxl,$xl,$lg,$md,$sm,$xs} {
        left: 0;
        right: 0;
        text-align: center;
        margin: 0 auto;
    }
    & a{
        width: 80px;
        height: 80px;
        background: var(--tp-common-white);
        border-radius: 50%;
        color: var(--tp-theme-1);
        transition: 0.3s;
        display: inline-block;
        box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
        line-height: 80px;
        text-align: center;
        font-size: 20px;
        &::before{
            position: absolute;
            content: "";
            width: 80px;
            height: 80px;
            background: #ffffff;
            border-radius: 50%;
            z-index: -1;
            animation: pulse 2s infinite;
            left: 0;
            right: 0;
            @media #{$xxl,$xl,$lg,$md,$sm,$xs} {
                left: 0;
                right: 0;
                text-align: center;
                margin: 0 auto;
            }
        }
        &:hover{
            background-color: var(--tp-theme-1);
            color: var(--tp-common-white);
        }
    }
    &-3{
        transform: translateY(-50%);
        text-align: center;
        margin: 0 auto;
        left: 0;
        right: 0;
        & a::before{
            text-align: center;
            margin: 0 auto;
        }
        & a{
            &:hover{
                background: var(--tp-theme-1);
                color: var(--tp-common-white);
            }
        }
    }
}

// video-area   
.video{
    &__area{
        @include background();
        height: 700px;
        position: relative;
        @media #{$md} {
            height: 600px;
        }
        @media #{$sm,$xs} {
            height: 500px;
        }
    }
    &__button-2{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        left: 0;
        text-align: center;
        & a{
            width: 120px;
            height: 120px;
            background: var(--tp-theme-1);
            border-radius: 50%;
            color: var(--tp-common-white);
            transition: 0.3s;
            display: inline-block;
            box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
            line-height: 120px;
            text-align: center;
            font-size: 20px;
            transition: .3s;
            &:hover{
                background: var(--tp-common-white);
                color: var(--tp-theme-1);
                border-color: var(--tp-common-white);
            }
            &::before{
                position: absolute;
                content: "";
                width: 120px;
                height: 120px;
                border-radius: 50%;
                z-index: -1;
                animation: pulse 2s infinite;
                left: 0;
                right: 0;
                text-align: center;
                margin: 0 auto;
            }
        }
    }
    &__button-3{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        left: 0;
        text-align: center;
        & a{
            width: 120px;
            height: 120px;
            background: var(--tp-common-white);
            border-radius: 50%;
            color: var(--tp-theme-1);
            transition: 0.3s;
            display: inline-block;
            box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
            line-height: 120px;
            text-align: center;
            font-size: 20px;
            transition: .3s;
            &:hover{
                background: var(--tp-theme-1); 
                color: var(--tp-common-white);
            }
            &::before{
                position: absolute;
                content: "";
                width: 120px;
                height: 120px;
                background: var(--tp-common-white);
                border-radius: 50%;
                z-index: -1;
                animation: pulse 2s infinite;
                left: 0;
                right: 0;
                text-align: center;
                margin: 0 auto;
            }
        }
    }
}

//appoinment cta 
.appoinment-cta{
    margin-top: -40px;
    &__content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        z-index: 1;
        position: relative;
        @media #{$md,$sm,$xs} {
            display: block;
            text-align: center;
            padding: 25px 15px;
        }
        &-title{
            font-size: 24px;
            color: var(--tp-common-white);
            line-height: 1.3;
            margin-left: 40px;
            margin-bottom: 0;
            @media #{$md,$sm,$xs} {
                margin-bottom: 20px;
                margin-left: 0;
            }
        }
    }
}

// appoinment 2
.appoinment-cta-2{
    @media #{$lg} {
        padding-bottom: 30px;
    }
    &__content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        z-index: 1;
        position: relative;
        padding: 15px 30px;

        @media #{$md,$sm,$xs} {
           padding: 25px 30px;
            transform: translateY(-30px);
        }
        &-title{
            font-size: 14px;
            color: var(--tp-text-body);
            line-height: 1.3;
            margin-bottom: 0;
            font-weight: var(--tp-fw-regular);
            @media #{$md,$sm,$xs} {
                margin-bottom: 0;
            }
            & a{
                font-weight: var(--tp-fw-sbold);
                color: var(--tp-heading-primary);
            }
        }
        & a{
            font-size: 14px;
            font-weight: var(--tp-fw-sbold);
            &:hover{
                color: var(--tp-theme-1);
            }
        }
    }
}
