$white: #ffffff !default;
$gray-100: #f8f8f8 !default;
$gray-200: #b8b7b7 !default;
$gray-300: #959595 !default;
$gray-400: #7f7f7f !default;
$gray-500: #706f6f !default;
$gray-600: #595959 !default;
$gray-700: #494949 !default;
$gray-800: #373736 !default;
$gray-900: #1d1c1c !default;
$black: #000000 !default;

$red-100: #fac7bf !default;
$red-200: #fc9f92 !default;
$red-300: #fc6253 !default;
$red-400: #f82224 !default;
$red-500: #d92121 !default;
$red-600: #a52c24 !default;
$red-700: #822b22 !default;
$red-800: #5e251e !default;
$red-900: #2e1512 !default;

$green-100: #bedbb3 !default;
$green-200: #8fc678 !default;
$green-300: #56a731 !default;
$green-400: #4f8e32 !default;
$green-500: #497b31 !default;
$green-600: #3f632d !default;
$green-700: #365028 !default;
$green-800: #2a3b21 !default;
$green-900: #171f13 !default;

$blue-100: #cad3e8 !default;
$blue-200: #edf2f6 !default;
$blue-300: #7792e4 !default;
$blue-400: #5b79e0 !default;
$blue-500: #536bbc !default;
$blue-600: #46578e !default;
$blue-700: #3b476f !default;
$blue-800: #2e354f !default;
$blue-900: #191c26 !default;

$yellow-100: #ead273 !default;
$yellow-200: #cbb768 !default;
$yellow-300: #a4945a !default;
$yellow-400: #8b7f50 !default;
$yellow-500: #7a7048 !default;
$yellow-600: #61593c !default;
$yellow-700: #4f4933 !default;
$yellow-800: #3b3727 !default;
$yellow-900: #1f1c16 !default;

$aqua-100: #85e5c1 !default;
$aqua-200: #55cca3 !default;
$aqua-300: #52a485 !default;
$aqua-400: #4c8b73 !default;
$aqua-500: #477a65 !default;
$aqua-600: #3d6052 !default;
$aqua-700: #344f43 !default;
$aqua-800: #293b33 !default;
$aqua-900: #171e1a !default;
