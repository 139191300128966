@use '../utils' as *;

/*----------------------------------------*/
/*  00. Feature CSS START
/*----------------------------------------*/

//brand css
.brand{
	&__area{
		&-2{
			@include background();
			margin-top: -40px;
		}
		&-3{
			position: relative;
			z-index: 4;
			margin-bottom: -130px;
		}
	}
	&__title{
		position: relative;
		&::before{
			position: absolute;
			content: '';
			background: var(--tp-border-2);
			width: 100%;
			height: 1px;
			bottom: 50%;
			left: 0;
			right: 0;
			z-index: -1;
		}
		& span{
			font-size: 16px;
			font-weight: var(--tp-fw-sblod);
			color: var(--tp-theme-1);
			display: inline-block;
			background: var(--tp-common-white);
			padding: 0 10px;
		}
	}
	&__slider{
		padding: 40px 0;
		border-bottom: 1px  solid var(--tp-border-2);
		text-align: center;
		padding-bottom: 55px;
		&-item{
			& img{
				text-align: center;
				filter: grayscale(100%);
				transition: 0.3s;
				opacity: 0.6;
			}
			&:hover img{
				transform: translateY(-10px);
				opacity: 1;
			}
		}
	}
	&__image-item{
		padding: 45px 20px;
		text-align: center;
		position: relative;
		&-br{
			border-right: 1px solid #e5e5e5;
		}
		&-bb{
			border-bottom: 1px solid #e5e5e5;
		}
		&-ab{
			position: absolute;
			top: 0;
			background: var(--tp-theme-1);
			width: 100%;
			height: 100%;
			left: 0;
			right: 0;
			visibility: hidden;
			opacity: 0;
			transition: .3s;
			& img{
				position: relative;
				z-index: 1;
				top: 80%;
				transform: translateY(-80%);
				transition: .3s;
			}
		}
		&:hover{
			& .brand__image-item-ab{
				visibility: visible;
				opacity: 1;
				& img{
					top: 50%;
				    transform: translateY(-50%);
				}
			}
		}
		&-3{
			& img{
				filter: grayscale(100%);
				opacity: 0.5;
				transition: .3s;
			}
			&:hover img{
				opacity: 1;
				filter: grayscale(0%);
			}
		}
	}
	&__wrapper{
		background: var(--tp-common-white);
		border-radius: 6px;
		box-shadow: 3px 16px 23px 15px rgba(0, 0, 0, 0.04);
	}
}
.brand-border{
	@media #{$md} {
		& .row [class*="col-"]:nth-child(3) .brand__image-item{
			border-right: 0;
		}
		& .row [class*="col-"]:nth-child(4) .brand__image-item{
			border-right: 1px solid #e5e5e5;
		}
		& .row [class*="col-"]:nth-child(5) .brand__image-item{
			border-bottom: 1px solid #e5e5e5;
		}
		& .row [class*="col-"]:nth-child(6) .brand__image-item{
			border-right: 0;
			border-bottom: 1px solid #e5e5e5;
		}
		& .row [class*="col-"]:nth-child(8) .brand__image-item{
			border-right: 1px solid #e5e5e5;
		}
	}
	@media #{$sm} {
		& .row [class*="col-"]:nth-child(2) .brand__image-item{
			border-right: 0;
		}
		& .row [class*="col-"]:nth-child(5) .brand__image-item{
			border-bottom: 1px solid #e5e5e5;
		}
		& .row [class*="col-"]:nth-child(6) .brand__image-item{
			border-right: 0;
			border-bottom: 1px solid #e5e5e5;
		}
	}
	@media #{$xs} {
		& .row [class*="col-"]:nth-child(1) .brand__image-item{
			border-right: 0;
		}
		& .row [class*="col-"]:nth-child(4) .brand__image-item{
			border-right: 1px solid #e5e5e5;
		}
		& .row [class*="col-"]:nth-child(5) .brand__image-item{
			border-bottom: 1px solid #e5e5e5;
		}
		& .row [class*="col-"]:nth-child(6) .brand__image-item{
			border-bottom: 1px solid #e5e5e5;
		}
		& .row [class*="col-"]:nth-child(7) .brand__image-item{
			border-bottom: 1px solid #e5e5e5;
		}
	}
}