@font-face {
    font-family: 'saira_condensed';
    src: url('/fonts/Poppins-Regular.woff2') format('woff2'),
         url('/fonts/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'saira_condensed';
    src: url('/fonts/Poppins-Medium.woff2') format('woff2'),
         url('/fonts/Poppins-Medium.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "iconfont"; /* Project id 3661533 */
    src: url('/fonts/iconfont.woff2?t=1663728660228') format('woff2'),
         url('/fonts/iconfont.woff?t=1663728660228') format('woff'),
         url('/fonts/iconfont.ttf?t=1663728660228') format('truetype');
  }
  
  .iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-rongyu:before {
    content: "\e616";
  }
  
  .icon-kujialeqiyezhan_yijianshengchengpeijian:before {
    content: "\e651";
  }
  
  .icon-chuzhigongchang:before {
    content: "\e600";
  }
  
  .icon-peijian:before {
    content: "\e933";
  }
  
  .icon-cc-inbox:before {
    content: "\e65c";
  }
  
  .icon-chanpinfabu:before {
    content: "\e639";
  }
  
  .icon-shipin:before {
    content: "\e656";
  }
  
  .icon-duomeiti-:before {
    content: "\e709";
  }
  
  .icon-Player-:before {
    content: "\e72d";
  }
  
  .icon-xiazai:before {
    content: "\e618";
  }
  
  .icon-icon_xinyong_xianxing_jijin-:before {
    content: "\e607";
  }
  
  .icon-xinwen:before {
    content: "\e648";
  }
  
  .icon-wangzhantubiaoji2_huaban1fuben20:before {
    content: "\e608";
  }
  
  .icon-faq:before {
    content: "\e615";
  }
  .icon-panel:before {
    content: "\e6cf";
  }
  