@use '../utils' as *;

/*-----------------------------------------------------------------------------------

    Theme Name: Dustrilox - Construction & Industry HTML5 Template
    Author: Theme Pure
    Support: basictheme@gmail.com
    Description: Dustrilox - Construction & Industry HTML5 Template
    Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

    01. THEME DEFAULT CSS
    02. background CSS
	03. spacing css
	04. buttons css
	05. carousel css
	06. page-title css
	07. modal css
	08. section-title css
	09. breadcrumb css
	10. animation css
	11. preloader css
	12. header css
	13. meanmenu css
	14. slider css
	15. services css
	16. about css
	17. banner css
	18. feature css
	19. projects css
	20. testimonial css
	21. team css
	22. blog css
	23. brand css
	24. process css
	25. pricing css
	26. fact css
	27. portfolio css
	28. faq css
	29. contact css
	30. sidebar css
	31. footer css

**********************************************/




/*----------------------------------------*/
/*  01. THEME DEFAULT CSS START
/*----------------------------------------*/


*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/*---------------------------------
    typography css start 
---------------------------------*/
body {
	font-family: var(--tp-ff-body);
	font-size: var(--tp-fz-body);
	font-weight: normal;
	color: var(--tp-text-body);
	line-height: 26px;
}

a{
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--tp-ff-heading);
	color: var(--tp-heading-primary);
	margin-top: 0px;
	font-weight: var(--tp-fw-sbold);
	line-height: 1.2;
	@include transition(.3s);
}

h1 {
	font-size: var(--tp-fz-h1);
}
  
h2 {
font-size: var(--tp-fz-h2);
}

h3 {
font-size: var(--tp-fz-h3);
}

h4 {
font-size: var(--tp-fz-h4);
}

h5 {
font-size: var(--tp-fz-h5);
}

h6 {
font-size: var(--tp-fz-h6);
}

ul {
	margin: 0px;
	padding: 0px;
}

p {
	font-family: var(--tp-ff-p);
	font-size: 14px;
	font-weight: var(--tp-fw-normal);
	color: var(--tp-text-body);
	margin-bottom: 15px;
	line-height: 25px;
}


a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
	@include transition(.3s);
}

a:focus,
.button:focus {
	text-decoration: none;
	outline: none;
}

a:focus,
a:hover{
	color: inherit;
	text-decoration: none;
}

a,
button {
	color: inherit;
	outline: none;
	border: none;
	background: transparent;
}

button:hover{
	cursor: pointer;
}

button:focus{
    outline: 0; 
    border:0
}

.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}

input{
	outline: none;
}

input[type="color"] {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background: none;
	border: 0;
	cursor: pointer;
	height: 100%;
	width: 100%;
	padding: 0;
	border-radius: 50%;
}

*::-moz-selection {
	background: var(--tp-common-black);
	color: var(--tp-common-white);
	text-shadow: none;
}
::-moz-selection {
	background: var(--tp-common-black);
	color: var(--tp-common-white);
	text-shadow: none;
}
::selection {
	background: var(--tp-common-black);
	color: var(--tp-common-white);
	text-shadow: none;
}


*::-moz-placeholder {
	color: var(--tp-common-black);
	font-size: var(--tp-fz-body);
	opacity: 1;
}
*::placeholder {
	color: var(--tp-common-black);
	font-size: var(--tp-fz-body);
	opacity: 1;
}

/*---------------------------------
    common classes css start 
---------------------------------*/
.col-sm-1{
	@media #{$xs} {
		flex: 0 0 auto;
    	width: 8.33333333%;
	}
}
.col-sm-2{
	@media #{$xs} {
		flex: 0 0 auto;
    	width: 16.66666667%;
	}
}
.col-sm-3{
	@media #{$xs} {
		flex: 0 0 auto;
    	width: 25%;
	}
}
.col-sm-4{
	@media #{$xs} {
		flex: 0 0 auto;
    	width: 33.33333334%;
	}
}
.col-sm-6{
	@media #{$xs} {
		flex: 0 0 auto;
    	width: 50%;
	}
}
.wow {
    visibility: hidden;
}
bp-mobile-navigation{
	display: none;
}
.w-img{
	& img{
		width: 100%;
	}
}

.m-img{
	& img{
		max-width: 100%;
	}
}

.fix {
    overflow:hidden
}
.clear{
    clear: both;
}

.f-left {
	float: left
}
.f-right {
	float: right
}

.z-index-1{
	z-index: 1;
}

.z-index-11{
	z-index: 11;
}

.overflow-y-visible{
	overflow-x: hidden;
	overflow-y: visible;
}

.p-relative{
	position: relative;
}
.p-absolute{
	position: absolute;
}

.include-bg{
	@include background();
}
.b-radius{
	border-radius: 6px;
}
.lazyload {
	opacity: 0;
}

.lazyloading {
	opacity: 1;
	transition: opacity 300ms;
	background: #f7f7f7 url(../../media/loading.svg) no-repeat center;
}
.nice-select {
    -webkit-tap-highlight-color: transparent;
    border-radius: 5px;
    border: solid 1px #e8e8e8;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-family: inherit;
    font-size: 13px;
    font-weight: normal;
    font-weight: 400;
    height: 48px;
    line-height: 48px;
    outline: 0;
    padding-left: 18px;
    padding-right: 30px;
    position: relative;
    text-align: left!important;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    width: auto;
}
.nice-select {
    background: #F5F7FB;
    float: left;
    width: 100%;
    color: #7d93b2;
    font-family: 'Jost', sans-serif;
    border: 1px solid #e5e7f2;
}
.nice-select:hover {
    border-color: #dbdbdb;
}


/*----------------------------------------
    Body Overlay 
-----------------------------------------*/

.body-overlay{
    background-color: rgba($color: var(--tp-common-black), $alpha: .5);
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 99;
	left: 0;
	opacity: 0;
	visibility: hidden;
	@include transition(.3s);

	&:hover{
		cursor: pointer;
	}
}

.body-overlay.opened{
	opacity: 1;
    visibility: visible;
}

/*----------------------------------------
    Progress Wrap
-----------------------------------------*/

.progress-wrap{
	@media #{$xs}{
		right: 15px;
		bottom: 15px;
	}
}
 
.custom-container{
	@media (min-width:1400px) {
		max-width: 1680px;
	}
}


.custom-container-3{
	@media (min-width:1400px) {
		max-width: 1200px;
	}
}
  
.progress-wrap {
    position: fixed;
    right: 50px;
    bottom: 50px;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: inset  0 0 0 2px rgba(95, 58, 252,0.2);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
  
  .progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  .progress-wrap::after {
    position: absolute;
    content: '\f176';
    font-family: "Font Awesome 5 Pro";
    text-align: center;
    line-height: 46px;
    font-size: 20px;
    color: #2c3941;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }


  
  .progress-wrap svg path { 
    fill: none; 
  }
  
  .progress-wrap svg.progress-circle path {
    stroke: #2c3941;
    stroke-width: 4;
    box-sizing:border-box;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }

.ff-errors{
	color: #e12d39;
}
.ff-form-errors {
    --bs-alert-bg: transparent;
    --bs-alert-padding: 1rem 1rem;
    --bs-alert-margin-bottom: 1rem;
    --bs-alert-color: inherit;
    --bs-alert-border-color: transparent;
    --bs-alert-border: 1px solid var(--bs-alert-border-color);
    --bs-alert-border-radius: 0.25rem;
	--bs-alert-color: #842029;
    --bs-alert-bg: #f8d7da;
    --bs-alert-border-color: #f5c2c7;
    position: relative;
    padding: var(--bs-alert-padding);
    margin-bottom: var(--bs-alert-margin-bottom);
    color: var(--bs-alert-color);
    background-color: var(--bs-alert-bg);
    border: var(--bs-alert-border);
    border-radius: var(--bs-alert-border-radius,0);
}

/*------------------------------------*\
		#Carousel
\*------------------------------------*/
.owl-nav.disabled {
	display: none;
}

.carousel-dots {
	position: relative;
}

.carousel-dots button.owl-dot,
.carousel-dots button.owl-dot:focus {
	padding           : 0;
	background-color  : transparent;
	border            : none;
	outline           : none;
	-webkit-box-shadow: none;
	box-shadow        : none;
}

.carousel-dots .owl-dots {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-pack : center;
	-ms-flex-pack    : center;
	justify-content  : center;
	-webkit-box-align: end;
	-ms-flex-align   : end;
	align-items      : flex-end;
	z-index          : 5;
}

.carousel-dots .owl-dots .owl-dot {
	margin-right: 10px;
	display     : -webkit-inline-box;
	display     : -ms-inline-flexbox;
	display     : inline-flex;
}

.carousel-dots .owl-dots .owl-dot:last-child {
	margin-right: 0;
}

.carousel-dots .owl-dots .owl-dot.active span {
	background-color: var(--global--color-primary);
	height          : 6px;
}

.carousel-dots .owl-dots .owl-dot span {
	display           : inline-block;
	width             : 35px;
	height            : 3px;
	border-top        : 3px solid transparent;
	background-color  : #6b7475;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	margin-right      : 0;
	position          : relative;
}

@media screen and (max-width: 767.98px) {
	.carousel-dots .owl-dots .owl-dot span {
		width: 25px;
	}
}

@media screen and (min-width: 992px) {
	.carousel-dots.dots-side .owl-dots {
		position: absolute;
		bottom  : -17px;
		right   : 0;
	}
}

@media screen and (max-width: 991.98px) {
	.carousel-dots.dots-side .owl-dots {
		margin-bottom: 35px;
	}
}

.owl-carousel .owl-stage-outer {
	overflow: visible;
}

.owl-carousel .owl-stage-outer .owl-item {
	opacity: 0;
}

.owl-carousel .owl-stage-outer .owl-item.active {
	opacity: 1;
}

.carousel-navs {
	position: relative;
}

.carousel-navs .owl-nav span {
	font-size: 0;
}

@media screen and (min-width: 992px) {
	.carousel-navs .owl-nav {
		position         : absolute;
		top              : 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform    : translateY(-50%);
		transform        : translateY(-50%);
		right            : 0;
		left             : 0;
	}
}

.carousel-navs .owl-next,
.carousel-navs .owl-prev {
	position: absolute;
}

.carousel-navs .owl-next:hover::before,
.carousel-navs .owl-prev:hover::before {
	color: var(--global--color-primary);
}

.carousel-navs .owl-next:before,
.carousel-navs .owl-prev:before {
	font-family       : 'carousel';
	font-size         : 43px;
	font-weight       : 600;
	color             : #687596;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.carousel-navs .owl-prev {
	left: 50px;
}

.carousel-navs .owl-prev:before {
	content: "\e990";
}

.carousel-navs .owl-next {
	right: 50px;
}

.carousel-navs .owl-next:before {
	content: "\e991";
}

.carousel-navs .owl-dots {
	position: absolute;
	bottom  : 30px;
	width   : 100%;
}

.sm-services__area{
	position: relative;
	z-index: 2;
}