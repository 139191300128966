.accented {
    &::after {
        content: '';
        background-color: $plain-text;
        border: 0;
        display: flex;
        height: .25rem;
        margin: .5rem 0 2rem;
        width: 2rem;
    }

    @include color-modifiers(primary secondary) using ($color) {
        &::after {
            background-color: $color;
        }
    }
}
