@use '../utils' as *;

//button css
.tp-btn {
	display: inline-block;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background-color: var(--tp-theme-1);
	border-color: transparent;
	color: var(--tp-common-white);
	border-radius: 5px;
	font-size: 16px;
	font-weight: var(--tp-fw-sbold);
	height: 60px;
	line-height: 56px;
	padding: 0px 40px;
	border: 2px solid var(--tp-theme-1);
	border-style: solid;
	box-shadow: none;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	text-align: center;
	z-index: 1;
	position: relative;
	& i{
		margin-left: 15px;
	}
	&:hover {
		box-shadow: none;
		color: var(--tp-theme-1);
	}
	&:hover::after {
		transform: skewX(45deg) scale(1, 1);
	  }
	&::after {
		position: absolute;
		content: "";
		z-index: -1;
		display: block;
		left: -20%;
		right: -20%;
		top: -4%;
		height: 150%;
		width: 150%;
		bottom: 0;
		background: var(--tp-common-white);
		transform: skewX(45deg) scale(0, 1);
		transition: all 0.5s ease 0s;
	  }
}
.tp-btn-2{
	display: inline-block;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background-color: rgba(10, 10, 10, 0.7);
	color: var(--tp-common-white);
	border-radius: 5px;
	font-size: 16px;
	font-weight: var(--tp-fw-sbold);
	height: 60px;
	line-height: 56px;
	padding: 0px 40px;
	box-shadow: none;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	z-index: 1;
	position: relative;
	& i{
		margin-left: 15px;
	}
	&:hover {
		box-shadow: none;
		color: var(--tp-theme-1);
		border-color: var(--tp-common-white);
	}
	&:hover::after {
		transform: skewX(45deg) scale(1, 1);
	  }
	&::after {
		position: absolute;
		content: "";
		z-index: -1;
		display: block;
		left: -20%;
		right: -20%;
		top: -4%;
		height: 150%;
		width: 150%;
		bottom: 0;
		background: var(--tp-common-white);
		transform: skewX(45deg) scale(0, 1);
		transition: all 0.5s ease 0s;
	}
}
.tp-btn-ts{
	display: inline-block;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background-color: var(--tp-common-white);
	border-color: transparent;
	color: var(--tp-heading-primary);
	border-radius: 5px;
	font-size: 16px;
	font-weight: var(--tp-fw-sbold);
	height: 60px;
	line-height: 56px;
	padding: 0px 40px;
	border: 2px solid var(--tp-common-white);
	border-style: solid;
	box-shadow: none;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	z-index: 1;
	position: relative;
	& i{
		margin-left: 15px;
	}
	&:hover {
		box-shadow: none;
		color: var(--tp-common-white);
	}
	&:hover::after {
		transform: skewX(45deg) scale(1, 1);
	  }
	&::after {
		position: absolute;
		content: "";
		z-index: -1;
		display: block;
		left: -20%;
		right: -20%;
		top: -4%;
		height: 150%;
		width: 150%;
		bottom: 0;
		background: var(--tp-theme-1);
		transform: skewX(45deg) scale(0, 1);
		transition: all 0.5s ease 0s;
	  }
}
//button css
.tp-btn-ps {
	display: inline-block;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background-color: var(--tp-theme-1);
	border-color: transparent;
	color: var(--tp-common-white);
	font-size: 16px;
	font-weight: var(--tp-fw-sbold);
	height: 60px;
	line-height: 56px;
	padding: 0px 40px;
	border: 1px solid transparent;
	border-style: solid;
	box-shadow: none;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	text-align: center;
	z-index: 1;
	position: relative;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	& i{
		margin-left: 15px;
	}
	&:hover {
		box-shadow: none;
		color: var(--tp-theme-1);
	}
	&:hover::after {
		transform: skewX(45deg) scale(1, 1);
	  }
	&::after {
		position: absolute;
		content: "";
		z-index: -1;
		display: block;
		left: -20%;
		right: -20%;
		top: -4%;
		height: 150%;
		width: 150%;
		bottom: 0;
		background: var(--tp-common-white);
		transform: skewX(45deg) scale(0, 1);
		transition: all 0.5s ease 0s;
	  }
}

//button style home 2
.tp-btn-d {
	display: inline-block;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background-color: var(--tp-grey-8);
	border-color: transparent;
	color: var(--tp-heading-primary);
	border-radius: 5px;
	font-size: 16px;

	font-weight: var(--tp-fw-sbold);
	height: 60px;
	line-height: 56px;
	padding: 0px 40px;
	border: 2px solid var(--tp-grey-8);
	border-style: solid;
	box-shadow: none;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	z-index: 1;
	position: relative;
	& i{
		margin-left: 15px;
	}
	&:hover {
		box-shadow: none;
		color: var(--tp-common-white);
	}
	&:hover::after {
		transform: skewX(45deg) scale(1, 1);
	  }
	&::after {
		position: absolute;
		content: "";
		z-index: -1;
		display: block;
		left: -20%;
		right: -20%;
		top: -4%;
		height: 150%;
		width: 150%;
		bottom: 0;
		background: var(--tp-theme-1);
		transform: skewX(45deg) scale(0, 1);
		transition: all 0.5s ease 0s;
	  }
}
.tp-btn-df {
	display: inline-block;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background-color: var(--tp-common-white);
	border-color: transparent;
	color: var(--tp-heading-primary);
	border-radius: 5px;
	font-size: 16px;

	font-weight: var(--tp-fw-sbold);
	height: 60px;
	line-height: 56px;
	padding: 0px 40px;
	border: 2px solid var(--tp-grey-8);
	border-style: solid;
	box-shadow: none;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	z-index: 1;
	position: relative;
	& i{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 40px;
		visibility: hidden;
		opacity: 0;
		transition: .3s;
	}
	&:hover {
		box-shadow: none;
		color: var(--tp-common-white);
		padding-right: 75px;
	}
	&:hover i{
		visibility: visible;
		opacity: 1;
	}
	&:hover::after {
		transform: skewX(45deg) scale(1, 1);
	  }
	&::after {
		position: absolute;
		content: "";
		z-index: -1;
		display: block;
		left: -20%;
		right: -20%;
		top: -4%;
		height: 150%;
		width: 150%;
		bottom: 0;
		background: var(--tp-theme-1);
		transform: skewX(45deg) scale(0, 1);
		transition: all 0.5s ease 0s;
	  }
}
.tp-btn-df-active {
	display: inline-block;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background-color: var(--tp-theme-1);
	border-color: transparent;
	color: var(--tp-common-white);
	border-radius: 5px;
	font-size: 16px;

	font-weight: var(--tp-fw-sbold);
	height: 60px;
	line-height: 56px;
	padding: 0px 40px;
	padding-right: 75px;
	border: 2px solid var(--tp-theme-1);
	border-style: solid;
	box-shadow: none;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	z-index: 1;
	position: relative;
	& i{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 40px;
		transition: .3s;
	}
	&:hover {
		box-shadow: none;
		color: var(--tp-heading-primary);
		border-color: var(--tp-grey-8);
	}
	&:hover::after {
		transform: skewX(45deg) scale(1, 1);
		background: var(--tp-common-white);
	  }
	&::after {
		position: absolute;
		content: "";
		z-index: -1;
		display: block;
		left: -20%;
		right: -20%;
		top: -4%;
		height: 150%;
		width: 150%;
		bottom: 0;
		background: var(--tp-theme-1);
		transform: skewX(45deg) scale(0, 1);
		transition: all 0.5s ease 0s;
	  }
}
.tp-btn-df-active-2 {
	display: inline-block;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background-color: var(--tp-theme-1);
	border-color: transparent;
	color: var(--tp-common-white);
	border-radius: 5px;
	font-size: 16px;
	font-weight: var(--tp-fw-sbold);
	height: 80px;
	line-height: 76px;
	padding: 0px 40px;
	padding-right: 75px;
	border: 2px solid var(--tp-theme-1);
	border-style: solid;
	box-shadow: none;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	z-index: 1;
	position: relative;
	& i{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 40px;
		transition: .3s;
	}
	&:hover {
		box-shadow: none;
		color: var(--tp-heading-primary);
	}
	&:hover::after {
		transform: skewX(45deg) scale(1, 1);
		background: var(--tp-common-white);
	  }
	&::after {
		position: absolute;
		content: "";
		z-index: -1;
		display: block;
		left: -20%;
		right: -20%;
		top: -4%;
		height: 150%;
		width: 150%;
		bottom: 0;
		background: var(--tp-theme-1);
		transform: skewX(45deg) scale(0, 1);
		transition: all 0.5s ease 0s;
	}
	@media #{$xs} {
		padding: 0px 20px;
		padding-right: 64px;
	}
}

//button style home 3
.tp-btn-3 {
	display: inline-block;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background-color: var(--tp-theme-2);
	border-color: transparent;
	color: var(--tp-theme-1);
	border-radius: 5px;
	font-size: 16px;
	font-weight: var(--tp-fw-sbold);
	height: 60px;
	line-height: 56px;
	padding: 0px 40px;
	border: 2px solid var(--tp-theme-2);
	border-style: solid;
	box-shadow: none;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	z-index: 1;
	position: relative;
	& i{
		margin-left: 15px;
	}
	&:hover {
		box-shadow: none;
		color: var(--tp-common-white);
	}
	&:hover::after {
		transform: skewX(45deg) scale(1, 1);
	  }
	&::after {
		position: absolute;
		content: "";
		z-index: -1;
		display: block;
		left: -20%;
		right: -20%;
		top: -4%;
		height: 150%;
		width: 150%;
		bottom: 0;
		background: var(--tp-theme-1);
		transform: skewX(45deg) scale(0, 1);
		transition: all 0.5s ease 0s;
	  }
}
.tp-btn-4{
	display: inline-block;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background-color: #f5f5f5;
	border-color: transparent;
	color: var(--tp-heading-primary);
	border-radius: 10px;
	font-size: 16px;
	font-weight: var(--tp-fw-sbold);
	height: 60px;
	line-height: 56px;
	padding: 0px 40px;
	border: 2px solid #f5f5f5;
	border-style: solid;
	box-shadow: none;
	transition: all 0.3s ease 0s;
	text-align: center;
	cursor: pointer;
	z-index: 1;
	position: relative;
	& i{
		margin-left: 15px;
	}
	&:hover {
		box-shadow: none;
		color: var(--tp-theme-1);
	}
	&:hover::after {
		transform: skewX(45deg) scale(1, 1);
	  }
	&::after {
		position: absolute;
		content: "";
		z-index: -1;
		display: block;
		left: -20%;
		right: -20%;
		top: -4%;
		height: 150%;
		width: 150%;
		bottom: 0;
		background: var(--tp-common-white);
		transform: skewX(45deg) scale(0, 1);
		transition: all 0.5s ease 0s;
	}
}
.tp-btn-4-active {
	display: inline-block;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background-color: var(--tp-theme-1);
	border-color: transparent;
	color: var(--tp-common-white);
	border-radius: 10px;
	font-size: 16px;
	font-weight: var(--tp-fw-sbold);
	height: 60px;
	line-height: 56px;
	padding: 0px 40px;
	border: 2px solid var(--tp-theme-1);
	border-style: solid;
	box-shadow: none;
	transition: all 0.3s ease 0s;
	text-align: center;
	cursor: pointer;
	z-index: 1;
	position: relative;
	& i{
		margin-left: 15px;
	}
	&:hover {
		box-shadow: none;
		color: var(--tp-theme-1);
		border-color: #f5f5f5;
		background: #f5f5f5;;
	}
	&:hover::after {
		transform: skewX(45deg) scale(1, 1);
	  }
	&::after {
		position: absolute;
		content: "";
		z-index: -1;
		display: block;
		left: -20%;
		right: -20%;
		top: -4%;
		height: 150%;
		width: 150%;
		bottom: 0;
		background: var(--tp-common-white);
		transform: skewX(45deg) scale(0, 1);
		transition: all 0.5s ease 0s;
	  }
}

//about 
.tp-join-btn {
	display: inline-block;
	position: relative;
	overflow: hidden;
	z-index: 1;
	border-color: transparent;
	color: var(--tp-heading-primary);
	border-radius: 5px;
	font-size: 16px;
	font-weight: var(--tp-fw-sbold);
	height: 60px;
	line-height: 56px;
	padding: 0px 40px;
	border: 2px solid var(--tp-grey-8);
	border-style: solid;
	box-shadow: none;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	z-index: 1;
	position: relative;
	& i{
		margin-right: 10px;
		color: var(--tp-theme-1);
	}
	&:hover {
		box-shadow: none;
		color: var(--tp-common-white);
		& i{
			color: var(--tp-common-white);
		}
	}
	&:hover::after {
		transform: skewX(45deg) scale(1, 1);
	  }
	&::after {
		position: absolute;
		content: "";
		z-index: -1;
		display: block;
		left: -20%;
		right: -20%;
		top: -4%;
		height: 150%;
		width: 150%;
		bottom: 0;
		background: var(--tp-theme-1);
		transform: skewX(45deg) scale(0, 1);
		transition: all 0.5s ease 0s;
	  }
}
.tp-touch-btn{
	display: inline-block;
	position: relative;
	overflow: hidden;
	z-index: 1;
	border-color: transparent;
	color: var(--tp-heading-primary);
	border-radius: 5px;
	font-size: 16px;
	font-weight: var(--tp-fw-sbold);
	height: 60px;
	line-height: 56px;
	padding: 0px 40px;
	border: 2px solid var(--tp-grey-8);
	border-style: solid;
	box-shadow: none;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	z-index: 1;
	position: relative;
	& i{
		margin-left: 10px;
	}
	&:hover {
		box-shadow: none;
		color: var(--tp-common-white);
		border-color: var(--tp-common-white);
	}
	&:hover::after {
		transform: skewX(45deg) scale(1, 1);
	  }
	&::after {
		position: absolute;
		content: "";
		z-index: -1;
		display: block;
		left: -20%;
		right: -20%;
		top: -4%;
		height: 150%;
		width: 150%;
		bottom: 0;
		background: var(--tp-theme-1);
		transform: skewX(45deg) scale(0, 1);
		transition: all 0.5s ease 0s;
	}
}
.tp-btn-white {
	display: inline-block;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background-color: var(--tp-common-white);
	border-color: transparent;
	color: var(--tp-heading-primary);
	border-radius: 5px;
	font-size: 16px;
	font-weight: var(--tp-fw-sbold);
	height: 60px;
	line-height: 56px;
	padding: 0px 40px;
	border: 2px solid var(--tp-common-white);
	border-style: solid;
	box-shadow: none;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	text-align: center;
	z-index: 1;
	position: relative;
	box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
	& i{
		margin-left: 15px;
		color: var(--tp-text-body);
	}
	&:hover {
		box-shadow: none;
		color: var(--tp-common-white);
		& i{
			color: var(--tp-common-white);
		}
	}
	&:hover::after {
		transform: skewX(45deg) scale(1, 1);
	  }
	&::after {
		position: absolute;
		content: "";
		z-index: -1;
		display: block;
		left: -20%;
		right: -20%;
		top: -4%;
		height: 150%;
		width: 150%;
		bottom: 0;
		background: var(--tp-theme-1);
		transform: skewX(45deg) scale(0, 1);
		transition: all 0.5s ease 0s;
	  }
}