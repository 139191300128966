.eyebrow {
    font-size: 0.725rem;
    font-weight: bold;
    margin-bottom: .25rem;
    text-transform: uppercase;

    @include color-modifiers(primary secondary);

    &.-highlighted {
        @include color-modifiers(primary secondary) using ($color) {
            background-color: $color;
            color: blackwhite($color);
            display: inline-block;
            padding: 0 .25em;
        }
    }
}
