.linkGroups {
    display: flex;
    margin-bottom: 30px;

    .linkGroups__group {
        width: max-content;

        + .linkGroups__group {
            margin-left: 3rem;
        }
    }
}
