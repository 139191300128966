@use '../utils' as *;

/*----------------------------------------*/
/*  04. Portfolio CSS START
/*----------------------------------------*/
.portfolio{
    &-filter {
        border-bottom: 1px solid var(--tp-border-2);
        display: inline-block;
        padding: 0;
        & button {
            background: none;
            padding: 25px 20px;
            padding-top: 0;
            border: 0;
            text-transform: capitalize;
            font-weight: var(--tp-fw-sbold);
            font-size: 16px;
            position: relative;
            @media #{$xs,$sm} {
                margin-bottom: 20px;
            }
            &::before {
                content: '';
                width: 0;
                height: 4px;
                position: absolute;
                bottom: 0;
                left: 0;
                background: var(--tp-theme-1);
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
            &:hover::before{
                width: 100%;
                left: 0;
            }
        }
        & button.active {
            color: var(--tp-heading-primary);
            &::before{
                width: 100%;
            }
        }
        & button.active::before, .portfolio-filter button:hover::before {
            width: 100%;
            left: 0;
            -webkit-transition: width 0.3s ease;
            transition: width 0.3s ease;
        }
    }
    &-wrapper {
        position: relative;
        &:hover{
            & .portfolio-image::before {
                opacity: .8;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            }
            & .portfolio-caption{
                visibility: hidden;
                opacity: 0;
            }
            & .portfolio-caption-top, .portfolio-caption-bottom, .portfolio-caption-bottom-2 {
               visibility: visible;
               opacity: 1;
            }
            & .portfolio-caption-top{
                top: 50px;
                @media #{$xs} {
                    top: 20px;
                }
            }
            & .portfolio-caption-bottom{
                bottom: 50px;
                @media #{$xs} {
                    bottom: 20px;
                }
            }
            & .portfolio-caption-bottom-2{
                bottom: 50px;
                @media #{$xs} {
                    bottom: 20px;
                }
            }
        }
    }
    &-image {
        position: relative;
        &::before {
            background: #000;
            content: "";
            height: 100%;
            left: 0;
            opacity: 0;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            position: absolute;
            top: 0;
            -webkit-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
            width: 100%;
            border-radius: 6px;
        }
        &::after {
            background: #2527287a;
            content: "";
            height: 100%;
            left: 0;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            position: absolute;
            top: 0;
            -webkit-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
            width: 100%;
            border-radius: 6px;
        }
        & img{
            border-radius: 6px;
        }
        &-2{
            &::after{
                display: none;
            }
        }
    }
    &-caption {
        left: 50px;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        position: absolute;
        bottom: 50px;
        -webkit-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        right: 50px;
        transform: translateY(10px);
        transition: .3s;
        @media #{$xs} {
            right: 20px;
            bottom: 20px;
            left: 20px;
        }
        & h6 {
            font-size: 24px;
            letter-spacing: 0px;
            margin-bottom: 0;
            padding-bottom: 10px;
            position: relative;
            color: var(--tp-common-white);
            line-height: 1.3;
        }
        & p {
            text-transform: capitalize;
            color: var(--tp-common-white);
            opacity: .8;
        }
        &-top{
            left: 50px;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            position: absolute;
            top: 30px;
            -webkit-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
            right: 50px;
            transform: translateY(10px);
            transition: .3s;
            visibility: hidden;
            opacity: 0;
            @media #{$xs} {
                right: 20px;
                bottom: 20px;
                left: 20px;
            }
            & h6 {
                font-size: 24px;
                letter-spacing: 0px;
                margin-bottom: 0;
                padding-bottom: 10px;
                position: relative;
                color: var(--tp-common-white);
                line-height: 1.2;
                &:hover{
                    color: var(--tp-theme-1);
                }
            }
            & p {
                text-transform: capitalize;
                color: var(--tp-common-white);
            }
            &-2{
                & h6{
                    font-size: 30px;
                    @media #{$sm} {
                        font-size: 25px;
                    }
                    @media #{$xs} {
                        font-size: 20px;
                    }
                }
            }
        }
        &-bottom{
            position: absolute;
            bottom: 30px;
            left: 50px;
            visibility: hidden;
            opacity: 0;
            transition: .3s;
            @media #{$xs} {
                right: 20px;
                bottom: 20px;
                left: 20px;
            }
            & a{
                display: block;
                height: 60px;
                width: 60px;
                line-height: 60px;
                background: var(--tp-theme-1);
                font-size: 20px;
                color: var(--tp-common-white);
                border-radius: 6px;
                text-align: center;
                &:hover{
                    background: var(--tp-common-white);
                    color: var(--tp-theme-1);
                }
            }
            &-2{
                position: absolute;
                bottom: 30px;
                left: 50px;
                visibility: hidden;
                opacity: 0;
                transition: .3s;
                @media #{$xs} {
                    right: 20px;
                    bottom: 20px;
                    left: 20px;
                }
                & a{
                    font-size: 14px;
                    font-weight: var(--tp-fw-sbold);
                    color: var(--tp-common-white);
                    &:hover{
                        color: var(--tp-theme-1);
                    }
                }
            }
        }
    }
    &__details{
        margin-right: -90px;
        @media #{$md,$sm,$xs} {
            margin-right: 0;
        }
        &-title{
            font-size: 30px;
            margin-bottom: 15px;
        }
        & .pt-d-image{
            img{
                border-radius: 6px;
            }
        }
        & .ptd-descriptiopn{
            position: relative;
            & p{
                margin-left: 100px;
            }
        }
        & .p-quote {
            position: absolute;
            font-size: 50px;
            line-height: 70px;
            height: 70px;
            width: 70px;
            background: var(--tp-common-white);
            color: var(--tp-heading-primary);
            display: block;
            text-align: center;
            top: 0;
            box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
        }
        & .hg-light{
            display: inline-block;
            color: var(--tp-heading-primary);
            font-weight: var(--tp-fw-medium);
        }
        & blockquote{
            font-size: 20px;
            color: var(--tp-heading-primary);
            font-weight: var(--tp-fw-medium);
            line-height: 28px;
        }
        & .pt-sm-item{
            &-title{
                font-size: 20px;
                padding-bottom: 15px;
                border-bottom: 1px solid var(--tp-border-2);
                margin-bottom: 20px;
            }
            &-number{
                color: var(--tp-theme-1);
                display: inline-block;
            }
        }
    }
    &__sidebar{
        margin-left: 115px;
        @media #{$md,$sm,$xs} {
            margin-left: 0;
        }
        & .ps{
            &__item{
                border: 1px solid var(--tp-border-2);
                border-radius: 6px;
                line-height: 0;
                &-info{
                    padding: 40px;
                    & .ps-list{
                        & ul{
                            & li{
                                list-style: none;
                                font-size: 14px;
                                padding-bottom: 25px;
                                margin-bottom: 20px;
                                border-bottom: 1px solid var(--tp-border-2);
                                &:last-child{
                                    margin-bottom: 0;
                                    border: 0;
                                    padding-bottom: 10px;
                                }
                                & span{
                                    font-weight: var(--tp-fw-medium);
                                    color: var(--tp-heading-primary);
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
            &__title{
                font-size: 18px;
                margin-bottom: 30px;
            }
            &__form{
                .contact-filed {
                    position: relative;
                    &::after{
                        content: "\f007";
                        position: absolute;
                        left: 30px;
                        top: 18px;
                        font-family: "Font Awesome 5 Pro";
                        color: var(--tp-theme-1);
                    }
                    &.contact-icon-mail::after{
                        content: "\f0e0";
                    }
                    &.contact-icon-message::after{
                        content: "\f303";
                    }
                    &.contact-icon-website::after{
                        content: "\f0ac";
                    }
                    & input  {
                        width: 100%;
                        height: 60px;
                        border: 1px solid var(--tp-border-2);
                        padding-left: 55px;
                        padding-right: 30px;
                        color: var(--tp-heading-primary);
                        border-radius: 6px;
                        font-weight: 500;
                        transition: .3s;
                        &::-moz-placeholder {
                            color: #aeaeae;
                            font-size: 14px;
                            opacity: 1;
                        }
                        &::placeholder {
                            color: #aeaeae;
                            font-size: 14px;
                            opacity: 1;
                        }
                        &:focus{
                            border-color: var(--tp-theme-1);
                        }
                    }
                    & textarea{
                        width: 100%;
                        height: 250px;
                        border: 1px solid var(--tp-border-2);
                        padding-left: 55px;
                        padding-right: 30px;
                        padding-top: 15px;
                        color: var(--tp-heading-primary);
                        font-weight: 500;
                        transition: .3s;
                        border-radius: 6px;
                        box-shadow: none;
                        outline: none;
                        &::-moz-placeholder {
                            color: #aeaeae;
                            font-size: 14px;
                            opacity: 1;
                        }
                        &::placeholder {
                            color: #aeaeae;
                            font-size: 14px;
                            opacity: 1;
                        }
                        &:focus{
                            border-color: var(--tp-theme-1);
                        }
                    }
                }
            }
        }
    }
}
