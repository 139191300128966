.field {
    $b: &;
    margin-bottom: 1rem;

    &__required {
        color: $red-400;
        font-weight: bold;
    }

    &__input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    &__help {
        color: $meta-text;
    }

    &__error {
        color: $error;
    }

    &.-error {
        #{$b}__input {
            border-color: $error;
        }
    }

    &.-outlined {
        #{$b}__input {
            border: 2px solid $primary;
        }
    }

    &.-checkbox {
        display: flex;

        #{$b}__input {
            flex: 0 0 1rem;
            height: $body-line-height * 1rem;
            margin: 0 $thin-h-space;
            order: -1;
        }

        #{$b}__label {
            font-weight: inherit;
            user-select: none;
        }
    }
}

input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="reset"],
input[type="search"],
input[type="search"],
input[type="submit"],
input[type="tel"],
input[type="text"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea {
    @extend .field__input;
}

