
/* 00. ========= pluse animation ========== */
@-webkit-keyframes pulse {
	0% {
	  -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}
@keyframes pulse {
0% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
}
70% {
	-moz-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	box-shadow: 0 0 0 45px rgba(255,255,255, 0);
}
100% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}



/* 00. ========= video-ripple ========== */
@keyframes video-ripple {
	0%, 35% {
	  -webkit-transform: scale(0);
	  transform: scale(0);
	  opacity: 1;
	}
	50% {
	  -webkit-transform: scale(1.3);
	  transform: scale(1.3);
	  opacity: 0.8;
	}
	100% {
	  opacity: 0;
	  -webkit-transform: scale(1.5);
	  transform: scale(1.5);
	}
}
@-webkit-keyframes video-ripple {
0%, 35% {
	-webkit-transform: scale(0);
	transform: scale(0);
	opacity: 1;
}
50% {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
	opacity: 0.8;
}
100% {
	opacity: 0;
	-webkit-transform: scale(1.5);
	transform: scale(1.5);
}
}
  
/* 00. ========= FadeIn custom ========== */
@-webkit-keyframes fadeInUp2 {
	0% {
	  opacity: 0;
	  -webkit-transform: translateY(20px);
	  transform: translateY(20px);
	}
  
	100% {
	  opacity: 1;
	  -webkit-transform: translateY(0);
	  transform: translateY(0);
	}
}
@keyframes fadeInUp2 {
0% {
	opacity: 0;
	-webkit-transform: translateY(20px);
	-ms-transform: translateY(20px);
	transform: translateY(20px);
}

100% {
	opacity: 1;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}
}
.fadeInUp2 {
-webkit-animation-name: fadeInUp2;
animation-name: fadeInUp2;
}


/* 00. ========= modeal-video-animation ========== */
@keyframes waveAlarm{
    0%{
        transform: scale(1);
        opacity: 40%;
    }
    100%{
        transform: scale(2);
        opacity: 0;
    }
}

/* 00. ========= ripple animation ========== */
@keyframes popupBtn{
    0% {
        transform: scale(1);
        opacity: .6;
    }
    50% {
        transform: scale(1.6);
        opacity: 0.3;
    }
    100% {
        transform: scale(2.2);
        opacity: 0;
    }
}

/* 00. ========= sticky animation ========== */
@-webkit-keyframes sticky {
0% {
	transform: translateY(-100%);
}
100% {
	transform: translateY(0%);
}
}

@keyframes sticky {
0% {
	transform: translateY(-100%);
}
100% {
	transform: translateY(0%);
}
}