.section {

    &.-twoColumn {

        .section__content {
            display: flex;
            flex-direction: column;
        }

        @include at(lg) {
            .section__content {
                flex-direction: row;
            }
            .section__item {
                @include wrap(1, 2);

            }
        }
    }

    &.-threeColumn {
        .section__content {
            display: flex;
            flex-direction: column;
        }

        @include at(md) {
            .section__content {
                flex-direction: row;
            }
            .section__item {
                @include wrap(1, 2);
            }
        }

        @include at(lg) {
            .section__content {
                flex-direction: row;
            }
            .section__item {
                @include wrap(1, 3);
            }
        }
    }
    &.-fourColumn {
        .section__content {
            display: flex;
            flex-direction: column;
        }

        @include at(md) {
            .section__content {
                flex-direction: row;
            }
            .section__item {
                @include wrap(1, 3);
            }
        }

        @include at(lg) {
            .section__content {
                flex-direction: row;
            }
            .section__item {
                @include wrap(1, 4);
            }
        }
    }
    &.-sixColumn {
        .section__content {
            column-count: 3;
            display: flow-root;
            flex-wrap: wrap;
            flex-direction: row;
            .section__item {
                height: 100%;
                flex: 0;
                margin-bottom: 40px;
            }
        }

        @include at(md) {
            .section__content {
                display: flex;
                flex-wrap: wrap;
            }
            
        }

        @include at(lg) {
            .section__content {
                column-count: 3;
                display: flow-root;
                flex-wrap: wrap;
            }
        }
    }
}
