@use '../utils' as *;

/*--
    - Background color
-----------------------------------------*/
.grey-bg {
	background: var(--tp-grey-1);
}
.grey-bg-5 {
	background: var(--tp-grey-5);
}
.grey-bg-8 {
	background: var(--tp-grey-8);
}
.theme-bg{
	background: var(--tp-theme-1);
}

.white-bg {
	background: var(--tp-common-white);
}

.black-bg {
	background: var(--tp-common-black);
}
.black-bg-2 {
	background: var(--tp-common-black-2);
}
.black-bg-3 {
	background: var(--tp-heading-primary);
}

.bg-theme {
	background-color: var(--global--color-primary) !important;
}

.bg-theme2 {
	background-color: var(--global--color-heading) !important;
}

.bg-gray {
	background-color: var(--global--color-gray) !important;
}

.bg-grey {
	background-color: #f6f7f8 !important;
}

.bg-dark-gray {
	background-color: #3a3a3a;
}

.bg-dark {
	background-color: #222222 !important;
}

.bg-secondary {
	background-color: var(--global--color-secondary);
}

/* Background Image */
.bg-section {
	position           : relative;
	overflow           : hidden;
	z-index            : 1;
	background-size    : cover;
	background-repeat  : no-repeat;
	background-position: center center;
	width              : 100%;
	height             : 100%;
}

.bg-parallax {
	background-attachment: fixed;
}

.bg-overlay:before {
	content : "";
	display : inline-block;
	height  : 100%;
	left    : 0;
	position: absolute;
	top     : 0;
	width   : 100%;
	z-index : -1;
}

.bg-overlay-light:before {
	background-color: var(--global--color-white);
	opacity         : 0.8;
}

.bg-overlay-dark:before {
	background-color: rgba(29, 42, 77, 0.45);
}

.bg-overlay-dark-2:before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(#1B1A1A80), to(#00000000));
	background-image: -o-linear-gradient(top, #1B1A1A80 0%, #00000000 100%);
	background-image: linear-gradient(180deg, #1B1A1A80 0%, #00000000 100%);
	opacity         : 0.55;
}

.bg-overlay-dark-3:before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(#1B1A1A80), to(#00000000));
	background-image: -o-linear-gradient(top, #1B1A1A80 0%, #00000000 100%);
	background-image: linear-gradient(180deg, #1B1A1A80 0%, #00000000 100%);
}

.bg-overlay-dark-slider:before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(#14191c), color-stop(82%, rgba(20, 25, 28, 0)));
	background-image: -o-linear-gradient(top, #14191c 0%, rgba(20, 25, 28, 0) 82%);
	background-image: linear-gradient(180deg, #14191c 0%, rgba(20, 25, 28, 0) 82%);
}

.bg-overlay-dark-slider-2:before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(#14191c), color-stop(82%, rgba(20, 25, 28, 0)));
	background-image: -o-linear-gradient(top, #14191c 0%, rgba(20, 25, 28, 0) 82%);
	background-image: linear-gradient(180deg, #14191c 0%, rgba(20, 25, 28, 0) 82%);
}

.bg-overlay-theme:before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(#32C36CD9), color-stop(82%, #32C36C));
	background-image: -o-linear-gradient(top, #32C36CD9 0%, #32C36C 82%);
	background-image: linear-gradient(180deg, #32C36CD9 0%, #32C36C 82%);
}

.bg-overlay-theme2:before {
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, rgba(36, 54, 69, .7)), to(#253745E6));
	background-image: -o-linear-gradient(top, rgba(36, 54, 69, .7) 50%, #253745E6 100%);
	background-image: linear-gradient(180deg, rgba(36, 54, 69, .7) 50%, #253745E6 100%);
}

.bg-overlay-theme3:before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(50, 195, 108, 0.7)), color-stop(86%, rgba(50, 195, 108, 0.5)));
	background-image: -o-linear-gradient(top, rgba(50, 195, 108, 0.7) 0%, rgba(50, 195, 108, 0.5) 86%);
	background-image: linear-gradient(180deg, rgba(50, 195, 108, 0.7) 0%, rgba(50, 195, 108, 0.5) 86%);
}

.bg-overlay-theme4 {
	background-color: var(--global--color-heading);
}

.bg-overlay-theme4:before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(#00000000), color-stop(70%, #1F313F));
	background-image: -o-linear-gradient(top, #00000000 0%, #1F313F 70%);
	background-image: linear-gradient(180deg, #00000000 0%, #1F313F 70%);
}

.bg-overlay-theme5:before {
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(46%, #32c36c), color-stop(144%, #32c36c5c));
	background-image: -o-linear-gradient(top, #32c36c 46%, #32c36c5c 144%);
	background-image: linear-gradient(180deg, #32c36c 46%, #32c36c5c 144%);
}

.bg-overlay-video:before {
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(42%, #1D2A4D03), color-stop(90%, #1D2A4D));
	background-image: -o-linear-gradient(top, #1D2A4D03 42%, #1D2A4D 90%);
	background-image: linear-gradient(180deg, #1D2A4D03 42%, #1D2A4D 90%);
	opacity         : 0.8;
}

.bg-overlay-white:before {
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #fff), to(#ffffffd6));
	background-image: -o-linear-gradient(top, #fff 50%, #ffffffd6 100%);
	background-image: linear-gradient(180deg, #fff 50%, #ffffffd6 100%);
}

.bg-overlay-white2:before {
	background-image: -webkit-gradient(linear, left bottom, left top, color-stop(34%, #fff), color-stop(44%, #ffffff00));
	background-image: -o-linear-gradient(bottom, #fff 34%, #ffffff00 44%);
	background-image: linear-gradient(0deg, #fff 34%, #ffffff00 44%);
}

.bg-overlay-white3:before {
	background-image: -webkit-gradient(linear, left top, left bottom, from(#00000000), color-stop(90%, #F6F7F8));
	background-image: -o-linear-gradient(top, #00000000 0%, #F6F7F8 90%);
	background-image: linear-gradient(180deg, #00000000 0%, #F6F7F8 90%);
}

/* Background Video */
.bg-video {
	position: relative;
}

.bg-video iframe {
	position         : absolute;
	top              : 0;
	left             : 0;
	right            : 0;
	-o-object-fit    : cover;
	object-fit       : cover;
	width            : 100%;
	height           : 100%;
	-webkit-transform: rotateZ(0);
	-ms-transform    : rotate(0);
	transform        : rotateZ(0);
}

.bg-video h2 {
	text-transform   : capitalize;
	position         : absolute;
	top              : 50%;
	left             : 50%;
	-webkit-transform: translate(-50%);
	-ms-transform    : translate(-50%);
	transform        : translate(-50%);
	z-index          : 5;
}

.no-shadow {
	-webkit-box-shadow: none !important;
	box-shadow        : none !important;
}

.bg-animate {
	background-size    : cover;
	background-position: 0px 0px;
	background-repeat  : repeat-x;
	-webkit-animation  : animatedBackground 30s linear infinite;
	animation          : animatedBackground 30s linear infinite;
}

@-webkit-keyframes animatedBackground {
	from {
		background-position: 0 0;
	}

	to {
		background-position: 100% 0;
	}
}

@keyframes animatedBackground {
	from {
		background-position: 0 0;
	}

	to {
		background-position: 100% 0;
	}
}