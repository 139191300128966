.carousel {

    &.-bleedRight {
        overflow: hidden;

        .carousel__carousel {
            overflow: visible;
        }
    }
}
.carousel__room-highlights {
    position: relative;
    width: 100%;
    height: 1.25rem;
    margin: 1.5rem 0;
    .carousel__room-highlights-card__carousel-points {
        position: absolute;
    }
    .carousel__room-highlights-card__carousel-controls-prev, .carousel__room-highlights-card__carousel-controls-next {
        color: rgb(28, 28, 28);
        cursor: pointer;
        line-height: 1.25rem;
        background: none;
        position: absolute;
        display: flex;
        align-items: center;
        border: none;
        .text{
            font: normal normal 700 0.875rem/1.25rem Swiss-721;
            display: block;
        }
        .line{
            background: $gray-200;
            border: 0px;
            box-sizing: border-box;
            height: 0.0625rem;
            width: 3.7375rem;
        }
        [class^="icon-"] {
            height: 1.25rem;
            opacity: 0;
            display: none;
            color: $gray-200;
        }
        [class^="icon-"]::before {
            color: $gray-200;
            font-size: 1.1rem;
        }
        &:hover{
            [class^="icon-"] {
                opacity: 1;
                display: block;
            }
            .line{
                display: none;
            }
        }
    }
    .carousel__room-highlights-card__carousel-controls-prev {
        left: auto;
        right: 18%;
        transform: translateX(-18%);
    }
    .carousel__room-highlights-card__carousel-controls-next {
        right: 0px;
    }
    .carousel__room-highlights-card__carousel-points-desktop{
        width: 30%;
        height: 1px;
        left: 0;
        top: 0;
        .swiper-pagination-progressbar-fill{
            height: 3px;
            top: -1px;
            overflow: hidden;
        }
    }
}
.dining__carousel{
    padding: 4rem 0;
    .carousel__container{
        position: relative;
        .swiper-button-prev {
            left: 26.6%;
        }
        .swiper-button-next{
            right: 26.6%;
        }
    }
    .section__header{
        text-align: center;
    }
    .swiper-slide{
        .card__background{
            opacity: .6;
            -webkit-transition: opacity 1s ease-out;
            transition: opacity 1s ease-out;
        }
        .card__titles{
            opacity: 0;
            -webkit-transition: opacity 1s ease-out;
            transition: opacity 1s ease-out;
            padding: 0.5rem 0;
        }
    }
    .swiper-slide.swiper-slide-active{
        .card__background{
            opacity: 1;
        }
        .card__titles{
            opacity: 1;
        }
    }
    @include at(md) {
        .swiper-pagination{
            display: none;
        }
    }
}