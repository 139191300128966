.linkGroup {
    min-width: 180px;
    margin-bottom: 20px;
    &:last-child{
        padding-left: 30px;
    }
    &__heading {
        font-weight: bold;
        padding: $thin-v-space 0;
        font-size: 1.125rem;
    }

    &__list {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        justify-content: center;

        &.-wide {
            columns: 3;
            column-gap: 3rem; 

            .linkGroup__link {
                break-inside: avoid;
            }
        }

        &.-secondary {
            margin-bottom: 2rem;
        }
    }

    &__item {
        margin-bottom: 0;
        & img{
            padding: 20px;
        }
    }

    &__link {
        display: block;
        padding: $thin-v-space 0;
        min-width: 150px;
        font-size: 0.875rem;
        color: var(--tp-text-body);
        & i{
            font-size: 40px;
        }
    }
}
