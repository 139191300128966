.siteHeader {
    box-shadow: $low-shadow;
    background: rgba(255,255,255,1);
    position: fixed;
    width: 100%;
    z-index: 50;
    transition: all 200ms linear;
    &__container {
        align-items: center;
        display: flex;
        padding-right: 0;
        justify-content: space-between;
    }

    &__brand {
        align-items: center;
        color: $primary;
        display: flex;
        gap: $h-space;
        margin-right: 2rem;
    }

    &__logo {
        margin-right: .5rem;
    }

    &__end {
        margin-left: auto;
    }

    @include at(lg) {
        &__brand {
            padding: $h-space 0;
        }
    }
}
