button,
input,
optgroup,
select,
textarea {
    font: inherit;
    margin: 0;
}

label {
    cursor: pointer;
    display: block;
    font-weight: bold;
    margin-bottom: 0.5em;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
    .help {
        color: $meta-text;
        font-size: 0.8rem;
        margin: 0;
    }
}

input[type="reset"],
input[type="search"],
input[type="text"],
input[type="submit"] {
    -webkit-appearance: none;
}

::-webkit-input-placeholder {
    color: $gray-400;
}

::ms-input-placeholder {
    color: $gray-400;
}

::-placeholder {
    color: $gray-400;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #f0f0f0;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    color: #000;
    cursor: pointer;
    height: 47px;
    overflow: hidden;
    padding: 0.5em 0 0.5em 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    position: relative;
    &::after{
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f111";
        font-family: fs-iconfont!important;
        font-size: 5px;
        font-style: normal;
        font-variant: normal;
        font-weight: 400;
        pointer-events: none;
        position: absolute;
        right: 16px;
        text-decoration: none;
        text-transform: none;
        top: 10px;
    }
}

textarea {
    height: 8em;
    overflow: auto;
    resize: vertical;
}

input[type="checkbox"],
input[type="radio"] {
    padding: 0;
}
