.primaryNav {
    display: none;

    &__list {
        @include no-bullets;
        display: flex;
        justify-content: end;
    }

    &__item {
        margin-bottom: 0;
    }

    &__link {
        display: block;
        padding: 2.4rem $h-space;
        font-weight: 600;
        color: $secondary;
        font-size: 1rem;
        font-family: saira_condensed,sans-serif;
        -webkit-transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
        transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
        &:hover,
        &:active {
        color: $primary;
    }
    }

    @include at(lg) {
        display: block;
    }
}
