///
/// = Base =
///

*,
*::before,
*::after {
    box-sizing: border-box;
}
:root {
    --swiper-theme-color: #12b0ea !important;
}

html {
    background-color: #FFF;
    -webkit-text-size-adjust: 100%;
}

body {
    color: $plain-text;
    font-family: $body-font-stack;
    font-size: $body-font-size;
    line-height: $body-line-height;
    margin: 0;
    background-color: #fff;
}

img,video {
    vertical-align: middle;
    height: auto;
    width: 100%;
}

::selection {
    background: $border;
}
.text--left{
    text-align: left;
}
.text--center{
    text-align: center;
}
.text--right{
    text-align: right;
}
.font-bold{
    font-weight: 700;
}