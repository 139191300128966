.menu {
    display: flex;
    flex-flow: column nowrap;

    &__list {
        @include no-bullets;
    }

    &__item {
        margin-bottom: 0;
    }

    &__link {
        margin-bottom: 0;
        display: block;
        padding: $thin-padding;
        transition: color $fade-easing $fast, background-color $fade-easing $fast;

        &:hover {
            background-color: $blue-100;
            color: $blue-600;
        }
    }
}
