@use '../utils' as *;

/*----------------------------------------*/
/*  04. PROJECTS CSS START
/*----------------------------------------*/

//project css
.project{
    &__area{
        position: relative;
    }
    &__inner{
        position: relative;
        width: 100%;
        display: flex;
        @media #{$sm,$xs} {
            display: block;
        }
    }
    &__info{
        position: relative;
        width: 50%;
        @media #{$sm,$xs} {
            width: 100%;
        }
        &-image{
            position: relative;
            &::before{
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(34, 35, 40, 0.5);
            }
            & img{
                width: 100%;
            }
        }
        &:hover .project__info-image {
            &::before{
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: .5;
                background: var(--tp-theme-1);
            }
        }
        &:hover .project__button{
            visibility: visible;
            opacity: 1;
        }
        &:hover .project__content{
            transform: translateY(-50%);
        }
    }
    &__content{
        position: absolute;
        top: 50%;
        transform: translateY(-23%);
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        transition: .3s;
        padding: 0 30px;
        & span{
            font-size: 14px;
            color: var(--tp-common-white);
            margin-bottom: 10px;
        }
        & .p-title{
            font-size: 40px;
            line-height: 1.3;
            color: var(--tp-common-white);
            @media #{$md,$sm,$xs} {
                font-size: 30px;
            }
        }
    }
    &__button{
        visibility: hidden;
        opacity: 0;
        transition: .3s;
    }
    &__slider{
        position: relative;
        margin-right: -750px;
        &-item{
            overflow: hidden;
            transition: .3s;
            &-image{
                @include background();
                min-height: 450px;
                border-radius: 6px;
                position: relative;
                &::before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: #2527287a;
                    visibility: hidden;
                    opacity: 0;
                    border-radius: 6px;
                }
            }
            & span{
                font-size: 14px;
                display: inline-block;
                margin-bottom: 5px;
            }
            &-title{
                font-size: 24px;
                line-height: 1.3;
                transition: .3s;
                @media #{$md} {
                    font-size: 18px;
                }
                &:hover {
                    color: var(--tp-theme-1);
                }
            }
            &-overlay{
                position: absolute;
                text-align: center;
                left: 0;
                right: 0;
                top: 45%;
                transform: translateY(-45%);
                margin: 0 auto;
                transition: .3s;
                visibility: hidden;
                opacity: 0;
                & a{
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    background: var(--tp-theme-1);
                    line-height: 50px;
                    font-size: 20px;
                    border-radius: 6px;
                    color: var(--tp-common-white);
                    transition: .3s;
                    &:hover{
                        background: var(--tp-common-white);
                        color: var(--tp-theme-1);
                    }
                }
            }
            &:hover{
                .project__slider-item-image::before{
                    visibility: visible;
                    opacity: 1;
                }
                .project__slider-item-overlay{
                    visibility: visible;
                    opacity: 1;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        @media #{$md,$sm,$xs} {
            margin-right: 0;
        }
    }
}

.casef-item {
    position: relative;
    padding-left: 120px;
    margin-top: 2px;
    @media #{$md,$sm,$xs} {
        padding-left: 0px;
    }
    .casef-item__left {
        position: absolute;
        top: 0;
        left: 0;
        width: 120px;
        height: 60px;
        font: 700 16px/60px "saira_condensed";
        text-align: center;
        background-color: var(--tp-theme-1);
        color: #fff;
        @media #{$md,$sm,$xs} {
            position: relative;
            width: 100%;
            display: block;
            font: 700 16px/30px "saira_condensed";
            height: 30px;

        }
    }
    .casef-options {
        padding: 10px 30px;
        background-color: #F5F7F9;
        @media #{$md,$sm,$xs} {
            padding: 10px 10px;

        }
        .casef-option {
            margin-right: 1.5em;
            position: relative;
            white-space: nowrap;
            font: 400 16px/40px "saira_condensed";
            color: #333;
            padding: 3px 5px;
            @media #{$md,$sm,$xs} {
                margin-right: 1em;
                font: 400 12px/30px "saira_condensed";
            }
            &.on{
                background-color: var(--tp-theme-1);
                color: #fff;
            }
            &.all{
                float: left;
                @media #{$md,$sm,$xs} {
                    float: none;
                }
            }
        }
        .casef-option::after {
            content: "";
            position: absolute;
            top: 50%;
            right: -0.75em;
            width: 1px;
            height: 14px;
            margin-top: -7px;
            background-color: #333;
            opacity: .22;
            pointer-events: none;
            @media #{$md,$sm,$xs} {
                right: -0.5em;
            }
        }
        .casef-option:last-of-type {
            margin-right: 0;
        }
        .casef-option:last-of-type::after{
            display: none;
        }
    }
}
.product__area{
    background: #fff;
}