///
/// For messages to the user.
///
/// @color
///     Icon color.
/// @link-color
///     Optionally override the default link color.
/// @link-hover-color
///     Optionally override the link hover color.
///
@mixin messaging (
    $color: $info,
    $font-size: $body-font-size,
    $icon-size: 1.5rem,
    $line-height: $body-line-height,
    $link-color: inherit,
    $link-hover-color: inherit
) {
    $b: &;

    $leading: $font-size * $line-height;

    background-color: $gray-100;
    box-shadow: .25rem 0 0 inset, $med-shadow;
    font-size: $font-size;
    line-height: $line-height;
    margin-bottom: 2rem;
    padding: $padding;
    display: flex;

    &__icon {
        height: $icon-size;
        flex-shrink: 0;
        margin-right: $h-space;
        width: $icon-size;
        @if $leading != $icon-size {
            transform: translateY(divide($leading - $icon-size, 2));
        }
    }

    &__content {
        flex-grow: 1;
    }

    a {
        color: $link-color;
        text-decoration: underline;
        transition: color $fade-easing $fast;

        &:hover {
            color: $link-hover-color;
        }
    }

    @include color-modifiers(success warning error info) using ($color) {
        box-shadow: .25rem 0 0 $color inset, $med-shadow;
        color: $plain-text;

        #{$b}__icon {
            color: $color;
        }
    }

    &.-filled {
        box-shadow: $med-shadow;

        @include color-modifiers(success warning error info) using ($color) {
            background-color: $color;
            color: blackwhite($color, $plain-text);

            #{$b}__icon {
                color: inherit;
            }
        }
    }
}

.messaging {
    @include messaging;
}
