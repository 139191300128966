///
/// container mixin
///
@mixin container($max-width: $container) {
    margin-left: auto;
    margin-right: auto;
    max-width: $max-width;
    position: relative;
}

.container {
    @include container;
}
