.section.-fourRelatedEntry{
    .-relatedEntry{
        color: #fff;
        margin-bottom: 15px;
        overflow: hidden;
        position: relative;
        .card__background{
            padding-top: 125%;
            &:after {
                background: -webkit-gradient(linear,left top,left bottom,from(transparent),to(#000));
                background: linear-gradient(180deg,transparent 0,#000);
                bottom: 0;
                content: "";
                display: block;
                height: 70%;
                position: absolute;
                width: 100%;
                z-index: 1;
                transition: transform 300ms cubic-bezier(0.83, 0, 0.17, 1);
            }
        }
        a:hover,
        a:focus{
            transition: transform 300ms cubic-bezier(0.83, 0, 0.17, 1);
            .card__background::after {
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.9) 100%);
                backdrop-filter: blur(13px);
            }
        }
        .card__overlay{
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            bottom: 30px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            left: 30px;
            margin-right: 30px;
            overflow-wrap: anywhere;
            pointer-events: none;
            position: absolute;
            z-index: 2;
            color: #fff;
            .card__heading{
                font-size: .75rem;
                font-style: normal;
                font-weight: 700;
                letter-spacing: .25em;
                line-height: 1.3333333333em;
                text-transform: uppercase;
                padding: 0;
                margin: 0;
            }
            .card__actions{
                padding: 0;
                font-size: .9375rem;
                font-style: normal;
                font-weight: 400;
                letter-spacing: .0333333333em;
                line-height: 1.6666666667em;
                display: block;
            }
        }
    }
    .section__footer{
        position: absolute;
        top: 70px;
        right: 0;
        margin-right: 40px;
        .button{
            padding: 0.5rem 0;
            box-shadow: none;
            border-bottom: 1px solid #000;
            color: #000;
            &:hover{
                background-color: rgba(0,0,0, 0);
                opacity: .7;
                color: #000;
            }
        }
    }
}
.section__item.-text{
    display: flex;
    flex-flow: wrap;
    .button{
        padding: 0.5rem 0;
        box-shadow: none;
        border-bottom: 1px solid #000;
        color: #000;
        background-color: rgba(0,0,0, 0);
        width: auto;
        border-width: 0 0 1px 0;
        &:hover{
            background-color: rgba(0,0,0, 0);
            opacity: .7;
            color: #000;
            border-bottom: 1px solid #000;
            box-shadow: none;
        }
    }
}
.-align__content__start{
    align-content:start;
}
.-align__content__center{
    align-content:center;
}
.-align__content__end{
    align-content:end;
}
.-twoColumn.-imageText{
    .section__header{
        text-align: center;
    }
}
#ExperienceCards-one,#ExperienceCards-three{
    .section__container{
        padding: 0;
        max-width: 100%;
        .section__item.-text{
            .-textPanel{
                padding-bottom: 0;
                padding-left: 10%;
                padding-right: 30%;
            }
        }
    }
}
#ExperienceCards-two{
    .section__container{
        padding: 0;
        max-width: 100%;
        .section__item.-text{
            .-textPanel{
                padding-bottom: 0;
                padding-right: 10%;
                padding-left: 30%;
            }
        }
    }
}
@media screen and (min-width: 1000px){
    .section.-twoColumn .section__item:nth-child(n) {
        margin: 0 !important;;
    }
}
.room-highlight{
    box-shadow: none !important;
    border: 1px solid rgb(238, 238, 238);
    .card__title{
        font: normal normal 700 1rem/1.25rem Noto Sans SC;
        color: #1c1c1c;
    }
}
.QuickLinks{
    position: absolute;
    right: 10%;
    bottom: 5%;
    .QuickLinks--desktopMobile{
        .linkGroup{
            padding: 0;
            .linkGroup__list{
                display: flex;
                align-items: center;
                .linkGroup__item{
                    .QuickLinks-link--container--light {
                        -webkit-box-orient: vertical;
                        -webkit-box-direction: normal;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        -webkit-box-pack: start;
                        -ms-flex-pack: start;
                        align-items: center;
                        border-radius: 5px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-direction: column;
                        flex-direction: column;
                        justify-content: flex-start;
                        margin-bottom: 15px;
                        overflow-y: hidden;
                        padding: 33% 10px;
                        text-align: center;
                        text-decoration: none;
                        height: 120px;
                        margin: 0 15px 30px;
                        padding: 34px 10px;
                        -ms-touch-action: none;
                        touch-action: none;
                        width: 130px;
                        background-color: #2c2c2c;
                        border: 1px solid #666;
                        color: #fff;
                        -webkit-transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
                        transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
                        &:hover{
                            background-color: rgba(0, 0, 0, 0.7);
                        }
                        .QuickLinks-link--icon {
                            font-size: 1.4375rem;
                            margin-bottom: 5px;
                            &::before{
                                color: #fff;
                                font-weight: 400;
                            }
                        }
                        .QuickLinks-link--text {
                            font-size: .5625rem;
                            letter-spacing: .12em;
                            word-break: break-word;
                        }
                    }
                }
            }
        }
    }
}
#Quote{
    .section__introduction{
        max-width: 750px;
        margin: 0 auto;
        text-align: center;
    }
}
.-titlesPanel{
    .section__subheading{
        line-height: 1.3em;
        margin: 3rem 0;
    }
}
.-imagebox{
    position: relative;
    .tile-desc {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        .tile-header {
            h5{
                line-height: 1.3em;
                font-size: 1.875rem;
                font-weight: 200;
                margin-bottom: 2rem;
                color: #fff;
                text-transform: uppercase;
            }
        }
        .desc {
            line-height: 1.6em;
            font-size: 0.85rem;
            color: #fff;
            width: 100%;
            padding: 0 3.5rem;
            margin-bottom: 3rem;
            a{
                color: #fff;
                border-bottom: 1px solid #536bbc;
            }
        }
    }
}
#exp-group{
    .section__container{
        padding-top: 0;
    }
}
#group-lead{
    .section__container{
        padding-bottom: 0;
    }
}
#intro{
    .section__container{
        display: flex;
        .section__content{
            text-align: left;
        }
    }
}
.left-arrow .section__image::before{
    border-width: 2.6rem 2.6rem 2.6rem 0;
    right:0;
    border-color: transparent #fff transparent transparent;
}
.right-arrow .section__image::before{
    border-width: 2.6rem 0 2.6rem 2.6rem;
    left:0;
    border-color: transparent transparent transparent #fff;
}
#spa-trip,#wedding-trip,#food-trip{
    .section__container{
        padding-top: 0;
        padding-bottom: 0;
        .section__item.-image {
            width: calc(66.66666667% + -20px);
            flex: auto;
            .section__image{
                position: relative;
                display: block;
                &::before{
                    content: "";
                    position: absolute;
                    top: 3rem;
                    border-style: solid;
                }
            }
        }
        .section__item.-text {
            width: calc(33.33333333% + -20px);
            flex: auto;
            .-textPanel{
                padding: 2rem;
                .button{
                    background-color: #000;
                    border: 1px solid #000;
                    text-align: center;
                    padding: 1.5rem 0;
                    letter-spacing: .005em;
                    text-decoration: none;
                    display: inline-block;
                    position: relative;
                    max-width: 34rem;
                    font-weight: 600;
                    line-height: 1em;
                    color: #fff;
                    font-size: 18px;
                    width: 80%;
                    -webkit-transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
                    transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
                    &:hover{
                        background-color: #fff;
                        border-color: #000;
                        color: #000;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
#ContactBar{
    .section__container{
        .section__content{
            background-color: #fff;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            align-items: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
            flex-direction: row;
            padding: 40px 60px;
            text-align: center;
            .section__item{
                width: auto;
                margin: 0;
                flex: auto;
            }
            .-text{
                color: #000;
                flex-grow: 1;
                text-align: left;
                flex: 10;
            }
            .button{
                background: none;
                border: 0;
                border-bottom: 1px solid #000;
                color: #000;
                cursor: pointer;
                font-family: Neue Helvetica,helvetica,sans-serif;
                font-size: .75rem;
                font-style: normal;
                font-weight: 700;
                letter-spacing: .25em;
                line-height: 1.3333333333em;
                padding: 0 0 3px;
                text-decoration: none;
                text-transform: uppercase;
                -webkit-transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
                transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
                &:hover{
                    border: none;
                    box-shadow: none;
                }
            }
            .section__item:last-child{
                .button{
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    align-items: center;
                    background-color: #000;
                    border: 1px solid #000;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    color: #fff;
                    cursor: pointer;
                    display: -webkit-inline-box;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    font-family: Neue Helvetica,helvetica,sans-serif;
                    font-size: .625rem;
                    font-style: normal;
                    font-weight: 700;
                    justify-content: center;
                    letter-spacing: .3em;
                    line-height: 1.3em;
                    min-width: 130px;
                    padding: 12px 20px;
                    text-align: center;
                    text-decoration: none;
                    text-transform: uppercase;
                    -webkit-transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
                    transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
                    &:hover{
                        background-color: #fff;
                        border-color: #000;
                        color: #000;
                    }
                }
            }
        }
    }
}
#wedding-trip{
    .section__container{
        padding-bottom: 40px;
    }
}
.Introduction-text {
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .0227272727em;
    line-height: 1.5em;
}

#hourboxs{
    .section__content{
        max-width: 100%;
    }
}
.TableList-item {
    border-top: 1px solid;
    display: flex;
    padding: 20px 0;
    font-size: .75rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0;
}
.TableList-item:last-of-type {
    padding-bottom: 0;
}
.TableList-item-title {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    max-width: 210px;
    text-align: left;
}
.TableList-item-description {
    -webkit-box-flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    padding-top: 10px;
    text-align: left;
    width: 100%;
}
.TableList-item-description {
    margin-left: auto;
    padding-top: 0;
    text-align: right;
}
.TableList-item-description-text:not(:last-of-type) {
    padding-bottom: 10px;
}
.room-white {
    box-shadow: none !important;
    
}
#ContactBar--dark{
    .section__container{
        .section__content{
            background-color: #000;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            align-items: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
            flex-direction: row;
            padding: 40px 60px;
            text-align: center;
            .section__item{
                width: auto;
                margin: 0;
                flex: auto;
            }
            .-text{
                color: #fff;
                flex-grow: 1;
                text-align: left;
                flex: 10;
            }
            .button{
                background: none;
                border: 0;
                border-bottom: 1px solid #fff;
                color: #fff;
                cursor: pointer;
                font-family: Neue Helvetica,helvetica,sans-serif;
                font-size: .75rem;
                font-style: normal;
                font-weight: 700;
                letter-spacing: .25em;
                line-height: 1.3333333333em;
                padding: 0 0 3px;
                text-decoration: none;
                text-transform: uppercase;
                -webkit-transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
                transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
                &:hover{
                    border: none;
                    box-shadow: none;
                }
            }
            .section__item:last-child{
                .button{
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    align-items: center;
                    background-color: #fff;
                    border: 1px solid #fff;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    color: #000;
                    cursor: pointer;
                    display: -webkit-inline-box;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    font-family: Neue Helvetica,helvetica,sans-serif;
                    font-size: .625rem;
                    font-style: normal;
                    font-weight: 700;
                    justify-content: center;
                    letter-spacing: .3em;
                    line-height: 1.3em;
                    min-width: 130px;
                    padding: 12px 20px;
                    text-align: center;
                    text-decoration: none;
                    text-transform: uppercase;
                    -webkit-transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
                    transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
                    &:hover{
                        background-color: #000;
                        border-color: #fff;
                        color: #fff;
                    }
                }
            }
        }
    }
}
.room-dark.card{
    box-shadow: none;
    background-color: #f5f5f5;
    .card__body {
        padding: 18px 20px 60px;
        .section__heading{
            text-align: center;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            letter-spacing: .0277777778em;
            line-height: 1.5em;
            position: relative;
            &::after{
                background-color: #000;
                content: "";
                display: block;
                height: 2px;
                margin: 15px auto 10px;
                width: 40px;
            }
        }
        .section__introduction{
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            letter-spacing: .0277777778em;
            line-height: 1.5em;
        }
    }
}
.food-image{
    .section__container{
        padding-top: 0;
    }
    figure{
        margin-left: 0;
        margin-right: 0;
    }
    .section__item:nth-child(n){
        width: calc(66.66666667% + -20px) !important;
        flex: auto !important;
    }
    .section__item:nth-child(2n){
        width: calc(33.33333333% + -20px) !important;
        padding-left: 15px;
        flex: auto !important;
    }
}
-textPanel ul{
    margin-bottom: 0;
}
.-width-third{
    width: calc(33.33333333% + -20px) !important;
    flex: auto !important;
}
.-width-two-thirds{
    width: calc(66.66666667% + -20px) !important;
    flex: auto !important;
    .-textPanel{
        padding-right: 10%;
        padding-top: 10px;
    }
}
.-quarter{
    width: calc(25% + -20px) !important;
    flex: auto !important;
}
.-half{
    width: calc(50% + -20px) !important;
    flex: auto !important;
}
.blocker{
    z-index: 20 !important;
}
.iziModal-content{

    .container{
        padding: 0;
        .section__header{
            border-bottom: 0.0625rem solid rgb(238, 238, 238);
            padding-top: 1rem;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            padding-left: 1rem;
        }
        .section__content{
            column-count: 3;
            display: flow-root;
            .section__item{
                margin: 0;
                flex: auto;
                display: block;
            }
            .-textPanel{
                display: inline-block;
                width: 100%;
                padding: 1rem 1rem;
                border-bottom: 0.0625rem solid rgb(238, 238, 238);
                p{
                    margin-bottom: 0.5rem;
                    font-size: 0.875rem;
                }
                h6{
                    font-size: 1rem;
                    margin: 0 0 1rem 0;
                }
            }
        }
    }
    .modal-footer{
        padding: 1rem;
        text-align: right;
        border-top: 1px solid #eee;
        .action{
            color: #000;
            font-weight: 700;
        }
    }
    .modal-header{
        background-color: #1c1c1c;
        padding: 0.5rem 1rem;
        h4{
            color: #fff;
        }
    }
}
.room__carousel{
    position: relative;
    padding-bottom: 3rem;
    overflow: hidden;
    .carousel__carousel{
        .card{
            margin-bottom: 0;
            .card__container{
                grid-gap: 0rem;
            }
        }
    }
    .room-details-modal-body__carousel-controls-prev, .room-details-modal-body__carousel-controls-next {
        bottom: -0.25rem;
        color: rgb(28, 28, 28);
        cursor: pointer;
        line-height: 1.25rem;
        background: none;
        position: absolute;
        border: none;
        display: flex;
        align-items: center;
    }
}
.room-details-modal-body__carousel-controls-prev {
    left: auto;
    right: 20%;
    transform: translateX(-20%);
}
.room-details-modal-body__carousel-controls-next {
    right: 28px;
}
.room-details-modal-body__carousel-controls-prev .line, .room-details-modal-body__carousel-controls-next .line {
    background: #a9a9a9;
    border: 0px;
    box-sizing: border-box;
    height: 0.0625rem;
    width: 3.975rem;
    display: block;
}
.room-details-modal-body__carousel-controls-prev .line{
    margin-left: 0.5rem;
}
.room-details-modal-body__carousel-controls-next .line{
    margin-right: 0.5rem;
}
.room-details-modal-body__carousel-controls-prev [class^="icon-"], .room-details-modal-body__carousel-controls-next [class^="icon-"] {
    height: 1.25rem;
    display: none;
}
.room-details-modal-body__carousel-controls-prev [class^="icon-"]{
    margin-left: 0.5rem;
}
.room-details-modal-body__carousel-controls-next [class^="icon-"]{
    margin-right: 0.5rem;
}
.room-details-modal-body__carousel-controls-prev:hover, .room-details-modal-body__carousel-controls-next:hover{
    .line{
        display: none;
    }
    [class^="icon-"]{
        display: block;
    }
}

.room-details-modal-body__carousel-points{
    position: absolute;
    left: 0;
    bottom: 0.25rem;
    display: flex;
    flex-shrink: 1;
    flex-grow: 0;
    flex-wrap: wrap;
    margin-left: 1rem;
}
.room-details-modal-body__carousel-points-left, .room-details-modal-body__carousel-points-right, .room-details-modal-body__carousel-points-seperator {
    color: rgb(28, 28, 28);
}
.room-details-modal-body__carousel-points-desktop{
    width: 300px;
    height: 0.0625rem;
    margin: 0;
    .swiper-scrollbar-drag{
        background-color: #ea9612;
    }
}
.iziModal{
    min-height: 50vh;
}

.CTA--underlined--dark {
    background: none;
    border: 0;
    border-bottom: 1px solid;
    color: #fff;
    cursor: pointer;
    font-family: Neue Helvetica,helvetica,sans-serif;
    font-size: .75rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: .25em;
    line-height: 1.3333333333em;
    padding: 0 0 3px;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
    transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
}
.ContactTable--dark{
    background-color: #000;
    .section__header{
        color: #fff;
        text-align: center;
    }
    .TableList-item{
        border-top: 1px solid #999;
        color: #fff;
    }
}
.freeform-row .freeform-column{
    position: relative;
    padding: 15px 0 10px !important;
    margin: 0 15px 17px !important;
    .freeform-input{
        &::placeholder {
            opacity: 1;
            transition: 1s ease;
        }
        &:focus::placeholder {
            opacity: 0;
            transition: 300ms ease;
        }
    }
    .freeform-label {
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        -webkit-transition: .5s ease;
        transition: .5s ease;
    }
    .freeform-input:focus + .freeform-label{
        opacity: 1;
        transform: translateY(-15px);
    }
} 

.RelatedLinks{
    .section__content{
        background-color: #000;
        color: #fff;
        .section__item:first-of-type{
            padding: 0 45px 0 0;
            border: solid #d8d8d8;
            border-width: 0 1px 0 0;
        }
        .section__item:last-of-type{
            padding-left: 115px;
            align-self: flex-start;
            padding: 0 0 0 45px;
            .-textPanel{
                width: 100%;
                p{
                    display: none;
                }
            }
            .button{
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: row;
                flex-direction: row;
                justify-content: space-between;
                background: none;
                border: 0;
                color: #fff;
                cursor: pointer;
                font-family: Neue Helvetica,helvetica,sans-serif;
                font-size: .75rem;
                font-style: normal;
                font-weight: 700;
                letter-spacing: .25em;
                line-height: 1.3333333333em;
                padding: 0 0 30px;
                text-decoration: none;
                text-transform: uppercase;
                -webkit-transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
                transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
                &::after{
                    -ms-flex-item-align: start;
                    align-self: flex-start;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-family: fs-iconfont!important;
                    font-style: normal;
                    font-variant: normal;
                    font-weight: 400;
                    text-decoration: none;
                    text-transform: none;
                    content: "\f105";
                    display: inline-block;
                    float: right;
                    font-size: .75rem;
                    padding-left: 10px;
                    vertical-align: middle;
                }
            }
        }
    }
    @media only screen and (min-width: 1701px){
        .section__content {
            padding: 90px 128px;
        }
    }
}