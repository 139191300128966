@mixin testimonial {
    &__quote {
        border: 0;
        font-size: 4.25rem;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        line-height: 1.5em;
        font-style: italic;
        font-weight: 400;
        p{
            margin-bottom: 0.5rem;
        }
        &::after,
        &::before {
            color: $gray-200;
            content: '”';
            display: inline-block;
            font-size: 300%;
            height: 1rem;
            line-height: 0;
            transform: translateY(300%);
            vertical-align: middle;
        }

        &::before {
            content: '“';
            text-indent: -2.5rem;
        }
    }
    @include at(md) {
        &__quote{
            letter-spacing: -.0065789474em;
        }
    }

    &__attribution {
        justify-content: center;
        max-height: 8rem;
    }
}

.testimonial {
    @include testimonial;
}
