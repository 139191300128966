///
/// = Tables =
///

@mixin table {
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 2rem;
    width: 100%;

    &__container {
        border-collapse: collapse;
        border-spacing: 0;
        margin-bottom: 2rem;
        width: 100%;
    }

    caption {
        font-size: 0.9rem;
        margin-bottom: 0.75em;
    }

    td,
    th {
        padding: $padding;
        text-align: left;
        vertical-align: top;
    }

    th {
        background-color: $primary;
        color: $white;
        white-space: nowrap;
    }

    tr {
        border-bottom: 1px solid $border;
        transition: background-color $fade-easing $fast;
    }


    tr:hover {
        background-color: $blue-100;
    }

    &.-scrollable {
        overflow-x: auto;
        width: calc(100% + #{$gutter * 2});
        display: flex;
        margin-left: -1 * $gutter;
        margin-right: -1 * $gutter;

        &::before,
        &::after {
            content: '';
            min-width: $gutter;
        }
    }
}

.table,
table {
    @include table;
}
